import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { STRINGS } from "./lib/Strings";
import Layout from "./Layout";
import "../../Styles/smartVerify.scss";

class Main extends Component {
  componentDidMount() {
    sessionStorage.removeItem("EMAIL");
    sessionStorage.removeItem("PHONE");
    sessionStorage.removeItem("OTP_ID");
    sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("SELFIE_IMAGE");
    sessionStorage.removeItem("INSTNT_DATA");
  }

  
  render() {
    const { history, brand, language, goToPage } = this.props;
    console.log(brand, language);
    return (
      <Layout
        history={history}
        brand={brand}
        language={language}
        goToPage={goToPage}
      >
        <Container className="form-fields inner-smartVerify">
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <h1>{STRINGS[brand][language].MAIN.HEADER_TITLE}</h1>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={{ span: 10, offset: 1 }}>
              <h3>{STRINGS[brand][language].MAIN.SUBHEADER_TITLE}</h3>
            </Col>
          </Row>
          <Row className="info-cards">
            <Col md={{ span: 6, offset: 3 }} className="info-card-container">
              <Card className="text-center">
                <Card.Body>
                  <div className="icon-wrapper">
                    <Card.Img
                      variant="top"
                      src="/mobile.svg"
                      className="icon icon-mobile"
                    />
                  </div>
                  <Card.Text>
                    {STRINGS[brand][language].MAIN.MOBILE_INFO_CARD}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {isMobile && (
              <>
                <Col md={4} className="info-card-container">
                  <Card className="text-center">
                    <Card.Body>
                      <div className="icon-wrapper">
                        <Card.Img
                          variant="top"
                          src="/license.svg"
                          className="icon icon-license"
                        />
                      </div>
                      <Card.Text>
                        {STRINGS[brand][language].MAIN.ID_INFO_CARD}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} className="info-card-container">
                  <Card className="text-center">
                    <Card.Body>
                      <div className="icon-wrapper">
                        <Card.Img
                          variant="top"
                          src="/camera.svg"
                          className="icon icon-camera"
                        />
                      </div>
                      <Card.Text>
                        {STRINGS[brand][language].MAIN.SELFIE_INFO_CARD}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} className="info-card-container">
                  <Card className="text-center">
                    <Card.Body>
                      <div className="icon-wrapper">
                        <Card.Img
                          variant="top"
                          src="/bulb.svg"
                          className="icon icon-bulb"
                        />
                      </div>
                      <Card.Text>
                        {STRINGS[brand][language].MAIN.ENVIRONMENT_INFO_CARD}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
          </Row>
          <Row className="submit-btn-container">
            <Col md={{ span: 6, offset: 3 }}>
              <Button
                variant="primary"
                block
                size="lg"
                onClick={() => goToPage("/info")}
              >
                {STRINGS[brand][language].MAIN.CONTINUE_CTA}
              </Button>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Main;
