import axios from "axios";

export const verifyDoc = async (request) => {
  const { data } = await axios.post(
    process.env.REACT_APP_INSTNT_URL +
      "/public/transactions/" +
      request.instnttxnid +
      "/attachments/verify/",
    request
  );
  return data;
};

export const submitForm = async (request) => {
  const { data } = await axios.post(
    "https://api-ca.instnt.org/submitformdata/v1.0",
    request
  );
  return data;
};

export const getAssertion = async (request) => {
  const { data } = await axios.post(
    "https://api-ca.instnt.org/getassertion/v1.0",
    request
  );
  return data;
};

export const ID_LIST = {
  en: [
    {
      code: "PASS",
      description: "Canadian passport",
      type: "Passport",
    },
    {
      code: "PRES",
      description: "Permanent resident card",
      type: "License",
    },
    {
      code: "1",
      description: "British Columbia Enhanced ID",
      type: "License",
    },
    {
      code: "1",
      description: "British Columbia Services Card",
      type: "License",
    },
    {
      code: "1",
      description: "British Columbia Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Alberta Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Saskatchewan Non-driver photo ID",
      type: "License",
    },
    {
      code: "1",
      description: "Saskatchewan Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Manitoba Driver's Licence",
      type: "License",
    },
    {
      code: "2",
      description: "Ontario Photo Card",
      type: "License",
    },
    {
      code: "1",
      description: "Ontario Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Québec Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "New Brunswick Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Nova Scotia Identification Card",
      type: "License",
    },
    {
      code: "1",
      description: "Nova Scotia Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Prince Edward Island Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Newfoundland and Labrador Driver's Licence",
      type: "License",
    },
    {
      code: "1",
      description: "Northwest Territories General Identification Card",
      type: "License",
    },
    {
      code: "1",
      description: "Northwest Territories Driver's Licence",
      type: "License",
    },
    {
      code: "5",
      description: "US Passport",
      type: "Passport",
    },
  ],
  ct: [
    {
      code: "PASS",
      description: "加拿大护照",
      type: "Passport",
    },
    {
      code: "PRES",
      description: "永久居民卡",
      type: "License",
    },
    {
      code: "1",
      description: "不列颠哥伦比亚省增强型身份证",
      type: "License",
    },
    {
      code: "1",
      description: "不列颠哥伦比亚省服务卡",
      type: "License",
    },
    {
      code: "1",
      description: "不列颠哥伦比亚省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "阿尔伯塔省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "萨斯喀彻温省非驾驶员身份证",
      type: "License",
    },
    {
      code: "1",
      description: "萨斯喀彻温省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "曼尼托巴省驾驶执照",
      type: "License",
    },
    {
      code: "2",
      description: "安大略省照片卡",
      type: "License",
    },
    {
      code: "1",
      description: "安大略省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "魁北克省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "新不伦瑞克省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "新斯科舍省身份证",
      type: "License",
    },
    {
      code: "1",
      description: "新斯科舍省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "爱德华王子岛驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "纽芬兰和拉布拉多驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "西北地区普通身份证",
      type: "License",
    },
    {
      code: "1",
      description: "西北地区驾驶执照",
      type: "License",
    },
    {
      code: "5",
      description: "美国护照",
      type: "Passport",
    },
  ],
  cs: [
    {
      code: "PASS",
      description: "加拿大护照",
      type: "Passport",
    },
    {
      code: "PRES",
      description: "永久居民卡",
      type: "License",
    },
    {
      code: "1",
      description: "不列颠哥伦比亚省增强型身份证",
      type: "License",
    },
    {
      code: "1",
      description: "不列颠哥伦比亚省服务卡",
      type: "License",
    },
    {
      code: "1",
      description: "不列颠哥伦比亚省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "阿尔伯塔省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "萨斯喀彻温省非驾驶员身份证",
      type: "License",
    },
    {
      code: "1",
      description: "萨斯喀彻温省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "曼尼托巴省驾驶执照",
      type: "License",
    },
    {
      code: "2",
      description: "安大略省照片卡",
      type: "License",
    },
    {
      code: "1",
      description: "安大略省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "魁北克省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "新不伦瑞克省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "新斯科舍省身份证",
      type: "License",
    },
    {
      code: "1",
      description: "新斯科舍省驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "爱德华王子岛驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "纽芬兰和拉布拉多驾驶执照",
      type: "License",
    },
    {
      code: "1",
      description: "西北地区普通身份证",
      type: "License",
    },
    {
      code: "1",
      description: "西北地区驾驶执照",
      type: "License",
    },
    {
      code: "5",
      description: "美国护照",
      type: "Passport",
    },
  ],
};

export const ID = {
  SCREENTITLE: "ABOUT YOU",
  UPLOADANDCONTINUE: "Upload & Continue",
  RETAKE: "Retake",
  CLICKTOBEGIN: "Tap To Begin",
  COMMONPART: {
    CAPTUREMODE: "Auto",
  },
  PARTONE: {
    SUBTITILE: "PART I: Identity Verification",
    ABOUTYOUINFO:
      "To verify your identity, you will need to upload pictures of your govt. issued photo ID.",
    LABEL: "Please select your ID type",
    IDLABEL: "ID",
    IDERROR: "your ID type",
    DEFAULTIDPLACEHOLDER: "Select your govt. issued ID",
  },
  PARTTWO: {
    SUBTITILE: "PART II: ID Scan",
    ABOUTYOUINFO: "Please take a picture of the front of your ID.",
    IDFRONTLABEL: "Front of ID card",
    DOCUMENTSETTING: {
      DOCUMENTSIDE: "Front",
      GLARETHRESHOLD: 15,
      TIMEOUT: 8,
    },
  },
  PARTTHREE: {
    SUBTITILE: "PART II: ID Scan",
    ABOUTYOUINFO: "Now take a picture of the back of your ID.",
    IDBACKLABEL: "Back of ID card",
    DOCUMENTSETTING: {
      DOCUMENTSIDE: "Back",
      DOCUMENTTYPE: "License",
      CAPTUREMODE: "Manual",
      GLARETHRESHOLD: 2.5,
      COMPRESSIONTYPE: "JPEG",
      COMPRESSIONQUALITY: "50",
      CAPTUREATTEMPTS: 1,
      MANUALTIMEOUT: 8,
    },
  },
  PARTFOUR: {
    SUBTITILE: "IDENTITY PART III: Selfie",
    ABOUTYOUINFO: "Lastly, you will need to upload a selfie.",
    SELFIELABEL: "Selfie",
  },
  PARTFIVE: {
    TITLE: "ABOUT YOU - REVIEW",
    REVIEWFORM: {
      PERSONALSECTION: "PERSONAL INFORMATION",
      FIRSTNAMELABEL: "First name",
      FIRSTNAMEREF: "firstName",
      MIDDLENAMELABEL: "Middle name",
      LASTNAMELABEL: "Last name",
      DOBLABEL: "Date of birth",
      DOBPLACEHOLDER: "YYYY-MM-DD",
      SCANLABEL: "whether information scanned correctly",
    },
    THANKYOUTITLE: "Thanks ",
    THANKYOUTEXT: "We are almost finished with setting up your ",
    REVIEWREQ: "Please review the information below and update it if needed.",
    CONFIRMQUESTION:
      "Did your personal information, like names and date of birth, scan correctly?",
    ADDRESSFORM: {
      ADDRESSSECTION: "RESIDENTIAL ADDRESS",
      MAILINGSECTION: "MAILING ADDRESS",
      ADDRESS0: "Address",
      ADDRESS1: "Address1",
      ADDRESS2: "Address2",
      CITY: "City",
      PROVINCE: "Province / Territory",
      POSTALCODE: "Postal code",
      ADDRESSCONFIRMLABEL: "whether address is same as mailing address",
      COUNTRY: "Country",
      COUNTRIES: {
        CA: { NAME: "Canada", CODE: "CA" },
        US: { NAME: "United States", CODE: "US" },
      },
    },
    ADDRESSCONFIRMQUESTION: "Is this address the same as your mailing address?",
    FIELDNAME: {
      MAILINGSAMEASCIVIC: "mailingSameAsCivic",
      SCANCONFIRMATION: "scanConfirmation",
    },
  },
};

export const IDEVERIFICATION_AUTH = {
  VERIFIED: "Verified",
  VERIFIED_BUT_NOT_PASS: "Personal Information verified but did not pass",
  RECAPTURE_SELFIE: "Recapture Selfie",
  RECAPTURE_ID: "Recapture ID",
  RECAPTURE_ALL: "Recapture All",
  ID_EXPIRATION: "ID Expiration",
  FAILED: "Failed",
  UNSUPPORTED_ID: "Unsupported ID",
  POTENTIAL: "Potential Paper ID",
  SUBMISSION_ERROR: "Submission Error",
  FRAUD_SHIELD: "Fraud Shield",
  DOCUMENT_NOT_PASS: "Document did not pass",
  AU122: "Selfie and the image in your document failed the comparison match.",
};
export const IDVERIFICATION_FAILED_CODE = {
  INITIAL: {
    profile: true,
    selectId: true,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: true,
  },
  FINAL: {
    profile: true,
    selectId: false,
    captureFrontId: false,
    captureBackId: false,
    captureSelfie: false,
  },
  AU100: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU101: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU102: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU103: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU104: {
    profile: true,
    selectId: false,
    captureFrontId: false,
    captureBackId: false,
    captureSelfie: false,
  },
  AU105: {
    profile: true,
    selectId: false,
    captureFrontId: false,
    captureBackId: false,
    captureSelfie: false,
  },
  AU106: {
    profile: true,
    selectId: false,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
  AU122: {
    profile: true,
    selectId: true,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: false,
  },
};
