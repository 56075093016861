import FIInformation from "Constants/FIInformation";

const MODALMESSAGES = {
  REGISTRATION: {
    TERMSERROR:
      "You must agree to the above statements, terms and/or conditions",
    USEREXIST: "Profile already exists. Please sign in to continue.",
  },
  VERIFICATIONCODE: {
    SUCCESSMSG: "Verification code sent successfully to your mobile device",
    ERRORMSG: "Something went wrong..",
    RESENDCODEERROR: "Email and mobile number not found..",
  },
  SIGNIN: {
    SUCCESSMSG: "Success! User exists",
    FAILURE:
      "There was an issue while adding your selection to your cart. <br/><br/> Please try adding it again from the marketplace.",
    SAMEPRODUCTS:
      "We notice that you have more than one of the same product application. <br/><br/> Please review your cart if this was unintended.",
    SOMEPRODUCTS:
      "We notice that you have an application in progress. <br/><br/> Please continue or remove this before beginning any new applications.",
  },
  FORGOT_PASSWORD: {
    ERRORMSG:
      "Something didn't work and the vrification code failed to send. Please try again later.",
    SUCCESSMSG:
      "We've sent a verification code to your registered mobile device.",
    SUCCESS_MAIL: "We've sent you an email to your registered email address.",
  },
  SETPASSWORD: {
    SUCCESSMSG: "Your marketplace profile has been created successfully.",
    ERRORMSG: "Please try again.",
  },
  CHANGE_PASSWORD: {
    SUCCESSMSG:
      "Your password has been updated successfully. Please login with your new password.",
    ERRORMSG: "Please try again.",
  },
  RECOVERPASSWORD: {
    SUCCESSMSG: "Your password has been updated successfully.",
    ERRORMSG: "Please try again.",
  },
  TERMS_AND_CONDITIONS: {
    INFO1: `Welcome... before you can begin your _PRODUCT_ application, you first need to be a member of ${FIInformation.FI_NAME}.`,
    INFO2: `These first few screens are just about you in general, in order to qualify your membership. It only takes a minute.`,
  },
  GETTINGSTARTED: {
    SUCCESSMSG: "SUCCESS",
  },
  COMPLIANCE: {
    SUCCESSMSG: "Application updated successfully.",
    ERRORMSG: "Failed to update application.",
  },
  CONFIRM_PAGE: {
    SUCCESS_TITLE: "Account created!",
    SUCCESS_JOINT_TITLE: "Joint Application",
    SUCCESS_MSG:
      "We are thrilled to set up your new account. An email confirmation has been sent to you for your records.",
    SUCCESS_JOINT_MSG:
      "Thank you for submitting your portion of the joint application. The primary applicant has been notified. Your next step will be signing your electronic agreement documents. You will receive an email containing a link that will enable you to complete your e-signatures. For any account online banking set up, please visit a branch in person.",
    PROCESS_WAIT:
      "Thank you for submitting your application. This may take some seconds to complete.",
  },
  ON_REVIEW: {
    TITLE: "Your application is in review.",
    MSG:
      "We are thrilled to set up your new account. An email confirmation has been sent to you for your records.",
  },
  EXISTINGMEMBER: {
    SUCCESS_TITLE: "Existing Customer",
    SUCCESS_MSG:
      "It appears you are already an existing customer. Go back and try clicking 'Yes'.",
  },
  PRODUCTREQUIRED: {
    REQUIRED_TITLE: "Hmm... Just one second.",
    REQUIRED_MSG1:
      "In order to be able to apply for this product, you will first need to have an account with us.",
    REQUIRED_MSG_2:
      "We will automatically add this account to your application.",
    REQUIRED_MSG_3: "Nothing extra is required from you.",
    ADD_PRODUCT_AND_CONTINUE_PT1: "Add a ",
    ADD_PRODUCT_AND_CONTINUE_PT1_2: "Account",
    ADD_PRODUCT_AND_CONTINUE_PT2: " & Continue",
    REQUIRED_BUTTON_PROCEED: "Proceed",
    GO_TO_MARKETPLACE: "Go To Marketplace",
    HELP_ME_CHOOSE: "Help Me Choose",
  },
  ONLINE_BANKING: {
    SUCCESS_MSG:
      "Thank you for completing your online banking set-up! You are now ready to access your accounts through digital banking.",
  },
  FUNDING_AMOUNT: {
    FUND_PRODUCT:
      "Thank you for completing your previous application. It appears there is another product application in queue.",
    FUND_PRODUCT_POPUP_MESSAGE:
      "Let's continue with setting up your $ product.",
  },
  SIGNATURE: {
    DOC_NOT_CREATED_TITLE: "e-Sign Documents",
    DOC_NOT_CREATED_MESSAGE:
      "Your documents are still being created. Please try again later.",
    SUCCESS_TITLE: "Congratulations _NAME_!",
    SUCCESS_MSG:
      "Congratulations on your new account! An email confirmation has been sent to you for your records. ",
    AOS_CONGRATS_MESSAGE:
      "Your application is complete. An email confirmation has been sent to you for your records.",
    LOS_CONGRATS_MESSAGE:
      "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
    BUSINESS_MESSAGE:
      "If you have a business application in progress or are looking for business products and/or services, you will need to sign out and go to business marketplace.",
  },
  JOINT_APPLICANT_STATUS: {
    PENDING_OS_TITLE: "Applicants: Pending",
    PENDING_OS_MESSAGE:
      "It appears your joint applicant(s) haven't completed their portion of the application yet.",
    JOINT_START_IN_SESSION:
      "Now it's time for your joint applicant with the email address _EMAIL_ to complete their portion of the application.",
  },
  DISBURSEMENT: {
    CONGRATS_TITLE: "Congratulations _NAME_!",
    CONGRATS_MESSAGE:
      "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
  },
  REPAYMENT: {
    CONGRATS_TITLE: "Congratulations _NAME_!",
    CONGRATS_MESSAGE:
      "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
  },
  LOAN_SUMMARY: {
    LOAN: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE:
        "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
    },
    REVIEW: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `Your loan is currently being reviewed. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
    PRE_QUALIFY: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
    LOC: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `We are thrilled to approve you for your line of credit. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
    HELOC: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `We are thrilled to approve you for your home equity line of credit. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
  },
  HELOC: {
    CANCEL_TITLE: "You do not qualify for HELOC.",
    CANCEL_DESCRIPTION:
      "Please apply for a line of credit or a personal loan instead.",
  },
  RECOMMEND: {
    POPUP_TITLE: "Limited Time Offer!",
    POPUP_SUBTITLE: "Recommended Promotion",
    POPUP_MESSAGE:
      "Great job at setting up your account! We have a special promotion for you…",
    ADD_PRODUCT_AND_CONTINUE_PT1: "Add a ",
    RECOMMEND_MESSAGE1:
      "Now that we know you, adding this account will only require a couple of clicks or taps.",
    RECOMMEND_BUTTON_PROCEED: "Proceed",
    RECOMMEND_BUTTON_SKIP: "Skip this promotion",
  },
  CENTRAL: {
    MSG:
      "Hey _NAME_, if you'd like to add more to your cart please choose the desired products from the marketplace.",
  },
  KYC: {
    TITLE: "Hmmm...",
    LIST: "Looks like you are about to change/update your: _LIST_",
    INFO1:
      "Because of the nature of this update, your application will need to be reviewed by our staff. We may request further documentation from you to support this update.",
    INFO2:
      "Please review these updates in the the summary page. If there are errors, you can go back and edit them and then continue submitting your application.",
  },
  BUSINESS_NON_MEMBER: {
    TITLE:
      "In order to apply for _PRODUCT_, you will first need a personal account with us",
    MESSAGE1: "We will save your business application for now.",
    MESSAGE2:
      "We will automatically direct you to personal banking marketplace and set you up with a free default personal account, where you can sign in or register.",
  },
};
export default MODALMESSAGES;
