import Card from "react-bootstrap/Card";
import { FIInformation } from "./lib/Constants";
import { STRINGS } from "./lib/Strings";
import Layout from "./Layout";
import { Col, Container, Row } from "react-bootstrap";
import React, { Component } from "react";
import {
  S_SMART_VERIFY_BRAND,
  S_SMART_VERIFY_LANGUAGE,
} from "../../Communication/Constants";

class End extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLeaving: false,
      success: false,
      error: false,
    };
  }

  
  componentDidMount() {
    const { history, goToPage } = this.props;
    const endFlag = sessionStorage.getItem("END_FLAG");
    if (endFlag) {
      if (endFlag === "LEAVING") {
        this.setState({
          isLeaving: true,
        });
      } else if (endFlag === "ERROR") {
        this.setState({
          error: true,
        });
      } else {
        this.setState({
          success: true,
        });
      }
      const brand = sessionStorage.getItem(S_SMART_VERIFY_BRAND);
      const language = sessionStorage.getItem(S_SMART_VERIFY_LANGUAGE);
      sessionStorage.clear();
      sessionStorage.setItem(S_SMART_VERIFY_BRAND, brand);
      sessionStorage.setItem(S_SMART_VERIFY_LANGUAGE, language);
    } else {
      goToPage("/");
    }
  }

  render() {
    const { isLeaving, success, error } = this.state;
    const { brand, language, goToPage } = this.props;
    return (
      <Layout
        atEnd={true}
        brand={brand}
        language={language}
        goToPage={goToPage}
      >
        <Container className="form-fields inner-smartVerify">
          <Row className="end-cards">
            {success && (
              <Col sm={12} className="end-card-container verified">
                <Card>
                  <Card.Body>
                    <div className="icon-wrapper">
                      <Card.Img
                        variant="top"
                        src="/check.svg"
                        className="icon icon-check"
                      />
                    </div>
                    <h1>
                      {
                        STRINGS[brand][language].END.IDENTITY_SUCCESS
                          .HEADER_TITLE
                      }
                    </h1>
                    <Card.Text>
                      {STRINGS[brand][language].END.IDENTITY_SUCCESS.BODY_0}
                    </Card.Text>
                    <Card.Text>
                      {STRINGS[brand][language].END.IDENTITY_SUCCESS.BODY_1}{" "}
                      <a
                        href={FIInformation[brand][language].FI_TEL}
                        target="_blank"
                      >
                        {STRINGS[brand][language].END.IDENTITY_SUCCESS.BODY_TEL}
                      </a>{" "}
                      {STRINGS[brand][language].END.IDENTITY_SUCCESS.BODY_1_2}{" "}
                      <a
                        href={FIInformation[brand][language].FI_EMAIL}
                        target="_blank"
                      >
                        {
                          STRINGS[brand][language].END.IDENTITY_SUCCESS
                            .BODY_EMAIL
                        }
                      </a>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <h4>
                      {STRINGS[brand][language].END.IDENTITY_SUCCESS.FOOTER}
                    </h4>
                  </Card.Footer>
                </Card>
              </Col>
            )}
            {error && (
              <Col sm={12} className="end-card-container not-verified">
                <Card>
                  <Card.Body>
                    <div className="icon-wrapper">
                      <Card.Img
                        variant="top"
                        src="/alert.svg"
                        className="icon icon-alert"
                      />
                    </div>
                    <h1>
                      {STRINGS[brand][language].END.IDENTITY_FAIL.HEADER_TITLE}
                    </h1>
                    <Card.Text>
                      {STRINGS[brand][language].END.IDENTITY_FAIL.BODY_0}
                    </Card.Text>
                    <Card.Text>
                      {STRINGS[brand][language].END.IDENTITY_FAIL.BODY_1}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <h4>{STRINGS[brand][language].END.IDENTITY_FAIL.FOOTER}</h4>
                  </Card.Footer>
                </Card>
              </Col>
            )}
            {isLeaving && (
              <Col sm={12} className="end-card-container escape">
                <Card>
                  <Card.Body>
                    <div className="icon-wrapper">
                      <Card.Img
                        variant="top"
                        src="/grin.svg"
                        className="icon icon-grin"
                      />
                    </div>
                    <h1>{STRINGS[brand][language].END.LEAVING.HEADER_TITLE}</h1>
                    <Card.Text>
                      {STRINGS[brand][language].END.LEAVING.BODY_0}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <h4>{STRINGS[brand][language].END.LEAVING.FOOTER}</h4>
                  </Card.Footer>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default End;
