const FIInformation = {
  FI_NAME: "Community Savings",
  FI_APPLICATION_NAME: "smartVerify",
  FI_EMAIL: "mailto:reception@comsavings.com",
  FI_CONDITIONS: "https://www.comsavings.com/legal/membership-application-consents",
  FI_PRIVACY: "https://www.comsavings.com/legal/membership-application-consents",
  FI_SITE: "https://www.comsavings.com",
  FI_CONTACT_US: "https://www.comsavings.com/more/contact-us",
  FI_OVERDRAFT_URL: "https://www.comsavings.com/more/get-rates",
  FI_BENEFICIARY_URL:
    "https://www.comsavings.com",
  FI_SHARE_AMOUNT: "$5",
  FI_BUSINESS_SHARE_AMOUNT: "$50",
  FI_OVERDRAFT_DEFAULT_AMOUNT: "$100.00",
  // FI_BOOK_APPOINTMENT: "https://mainstreetcu.coconutcalendar.com/service?utm_source=AOS_LOS&utm_medium=self_serve&utm_campaign=open_anytime",
};

export default FIInformation;
