import { ERROR_CODE, FIInformation } from "./Constants";
export const STRINGS = {
  MoB: {
    en: {
      MAIN: {
        HEADER_TITLE: `Welcome to ${FIInformation.MoB.en.FI_DISPLAY_NAME}'s online identity verification.`,
        SUBHEADER_TITLE: `In order to verify your identity you will need:`,
        MOBILE_INFO_CARD: `your mobile device`,
        ID_INFO_CARD: `a valid government-issued identity document`,
        SELFIE_INFO_CARD: `be prepared to take a selfie and photos of your ID`,
        ENVIRONMENT_INFO_CARD: `to be sure you are in a well-lit area`,
        CONTINUE_CTA: `Let's begin`,
      },
      INFO: {
        HEADER_TITLE: `Identity verification`,
        SUBHEADER_TITLE: `Enter your primary email address and mobile number.`,
        EMAIL_LABEL: `Your email address`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `Your mobile no.`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `By continuing you confirm that you have read and agree to the`,
        TERMS_LINK_LABEL_1: `terms and conditions.`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `Continue`,
        ERROR_CODE: {
          EMAIL_REQUIRED: `*Email is required`,
          EMAIL_CHARACTER_LIMIT: `*Email must be less than 100 characters`,
          EMAIL_VALID: `*Must be a valid email address`,
          PHONE_VALID: `*Phone number is not valid`,
          PHONE_REQUIRED: `*Phone number required`,
          TERMS_REQUIRED: `The terms and conditions must be accepted.`,
        },
      },
      VERIFY: {
        HEADER_TITLE: `Mobile phone verification`,
        SUBHEADER_TITLE: `We've sent a verification code to the mobile number you provided. Enter the verification code to continue.`,
        OTP_LABEL: `Verification code`,
        OTP_PLACEHOLDER: `code`,
        RESEND_TITLE: `Didn't receive a code?`,
        RESEND_OTP: `Resend Code`,
        CONTINUE_CTA: `Continue`,
        ERROR: {
          OTP_REQUIRED: `You must enter a verification to continue.`,
          OTP_INLINE_ERROR: `Invalid verification code`,
          ERROR_TITLE: `Error!`,
          ERROR_BODY_0: `The verification code you entered is invalid.`,
          ERROR_BODY_1: `Ensure the code is entered correctly; otherwise, request a new one.`,
        },
      },
      ID_SELECT: {
        HEADER_TITLE: "Select your ID type",
        ID_COMPATIBLE: "Be sure your ID is compatible",
        ID_SELECT_LABEL: "Select your govt. issued ID",
        DUMMY_OPTION: "Select an ID type",
        CONTINUE_CTA: `Continue`,
        SPINNER_MSG: `We need about 10 seconds or so to verify your ID... Please wait!`,
        OVERLAY_FRONT_ID: `Align your ID and tap to capture.`,
        OVERLAY_FRONT_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_BACK_ID: `Align your ID and tap to capture.`,
        OVERLAY_BACK_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_SELFIE: `Align your face and tap to capture.`,
        OVERLAY_SELFIE_AUTOTAKE: `Align your face within the box and hold steady.`,
        VERIFICATION_0: `Verifying your identity... please wait.`,
        VERIFICATION_1: `Validating ID to facial biometrics... please wait.`,
        VERIFICATION_2: `Validating social biometrics... please wait.`,
        ERROR: {
          ERROR_TITLE: `Error!`,
          ERROR_BODY: `Error encountered processing your info...`,
          ERROR_SELECT_ID: `Please select a valid ID type.`,
          ERROR_FRONT_ID: `Please capture the front of your id.`,
          ERROR_BACK_ID: `Please capture the back of your id.`,
          ERROR_SELFIE: `Please capture a selfie.`,
          ERROR_LANDSCAPE: `Landscape orientation is not supported. Rotate your device to a portrait orientation.`,
          ERROR_PORTRAIT: `Portrait orientation is not supported. <br/>Rotate your device to a landscape orientation.`,
        },
      },
      FRONT: {
        TAKE: "Take a photo of the front of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the front of your `,
        RETAKE: "Please take another photo of the front of your ",
        TAKE_PASSPORT: "Take a photo of the main photo page inside of your ",
        TAKE_PASSPORT_CAPTURED_TITLE: "Got it!",
        TAKE_PASSPORT_CAPTURED_SUBTITLE:
          "Below should be a photo of the main page inside of your ",
        RETAKE_PASSPORT:
          "Please re-take the photo of the main photo page inside your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        PROTIP: "Pro Tip",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      BACK: {
        TAKE: "Now take a photo of the back of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the back of your `,
        RETAKE: "Please take another photo of the back of your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      SELFIE: {
        TAKE: "Lastly, take a selfie.",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of you.`,
        RETAKE: "Please take another selfie.",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake selfie? Tap to retake",
        ABOUTYOUINFO: "For best results, take your selfie in a well lit area.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new selfie.`,
        NEXT: "Next",
      },
      REVIEW: {
        SECTION_HEADER_PERSONAL: `Personal`,
        SECTION_HEADER_ADDRESS: `Residential address`,
        CONTINUE_CTA: `Continue`,
        DESKTOP: {
          HEADER_TITLE: `Identity verification`,
          SUBTITLE: `Please enter your details.`,
        },
        MOBILE: {
          HEADER_TITLE: `Thank you`,
          BODY_0: `Please review the information as it was scanned.`,
          BODY_1: `You can update your info should you need to.`,
        },
        FORM: {
          FIRST_NAME_LABEL: `First name`,
          FIRST_NAME_PLACEHOLDER: `First name`,
          MIDDLE_NAME_LABEL: `Middle name`,
          MIDDLE_NAME_PLACEHOLDER: `Middle name`,
          LAST_NAME_LABEL: `Last name`,
          LAST_NAME_PLACEHOLDER: `Last name`,
          DOB_LABEL: `Date of birth`,
          DOB_PLACEHOLDER: `YYYY-MM-DD`,
          STREET_ADDRESS_LABEL: `Street address`,
          STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
          CITY_LABEL: `City`,
          CITY_PLACEHOLDER: `City`,
          PROVINCE_LABEL: `Province / Territory`,
          PROVINCE_PLACEHOLDER: `Province / Territory`,
          POSTAL_CODE_LABEL: `Postal code`,
          POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
          HEADER_TITLE: `Did the information from your ID scan correctly?`,
          YES: `Yes`,
          NO: `No`,
          UPDATE_INFO: `Be sure to update your information so it is correct and current, then hit "Continue".`,
        },
        ERROR_CODE: {
          FIRST_NAME_REQUIRED: `*First name is required`,
          LAST_NAME_REQUIRED: `*Last name is required`,
          DOB_REQUIRED: `*Date of birth is required`,
          ADDRESS_REQUIRED: `*Residential address is required`,
          CITY_REQUIRED: `*City is required`,
          PROVINCE_REQUIRED: `*Province / territory is required`,
          POSTAL_CODE_REQUIRED: `*Postal Code is required`,
          POSTAL_CODE_BAD_FORMAT: `Postal code does not appear to be valid. Please check for errors.`,
          NUMBERS_ERROR: `Numbers and some special characters are not accepted in names.`,
        },
      },
      END: {
        IDENTITY_SUCCESS: {
          HEADER_TITLE: `Success!`,
          BODY_0: `We have received your identification information and a member of the ${FIInformation.MoB.en.FI_DISPLAY_NAME} team will review the details and be in touch with you.`,
          BODY_1: `If you have any questions or concerns, please contact us at`,
          BODY_TEL: `${FIInformation.MoB.en.FI_TEL_PRETTY}`,
          BODY_1_2: `or`,
          BODY_EMAIL: `${FIInformation.MoB.en.FI_EMAIL_PRETTY}.`,
          FOOTER: `You can now close this browser window.`,
        },
        IDENTITY_FAIL: {
          HEADER_TITLE: `Hmm...`,
          BODY_0: `Unfortunately we were unable to automatically verify you at this time.`,
          BODY_1: `A confirmation email has been sent to your email address. A representative from ${FIInformation.MoB.en.FI_DISPLAY_NAME} will reach out to you soon.`,
          FOOTER: `You can now close this browser window.`,
        },
        LEAVING: {
          HEADER_TITLE: `Leaving us?`,
          BODY_0: `You are now finished with automatic verification. Any information you may have entered has been removed from our systems.`,
          FOOTER: `You can now close this browser window.`,
        },
      },
      MODAL: {
        CLOSE: "Close",
        OK: "OK",
      },
      MODAL_COMPATIBLE: {
        HEADER_TITLE: `ID Compatability Check`,
        BODY_0: `Our system uses machine reading and AI technologies to authentic ID documents. To prevent application delays, please upload government issued ID documents compatible with our system.`,
        BODY_1: `Compatible government issued ID documents have:`,
        BODY_1_ITEM_0: `your photo`,
        BODY_1_ITEM_1: `your date of birth`,
        BODY_1_ITEM_2: `machine readable fonts`,
        BODY_1_ITEM_3: `a bar code`,
        BODY_2: `Examples of compatible government issued IDs:`,
        BODY_2_ITEM_0: `Provincial / Territorial Issued Enhanced Driver's License`,
        BODY_2_ITEM_1: `Provincial / Territorial Issued Photo ID card`,
        BODY_2_ITEM_2: `Federally issued Secure Certificate of Indian Status`,
        BODY_2_ITEM_3: `Canadian Citizenship card`,
        BODY_2_ITEM_4: `Passport`,
        BODY_2_ITEM_5: `Candaian Forces Identification cards`,
        BODY_2_ITEM_6: `Permanent Resident card`,
        BODY_2_ITEM_7: `New (2023) Provincial / Territorial Issued PAL Firearms License`,
        BODY_2_ITEM_8: `USA/CAN Nexus card`,
        BODY_3_IMAGE_HEADER: `Example:`,
        BODY_3_IMAGE_ALT: `Example of a federally issued Certificate of Indian Status card.`,
        BODY_3: `NOTE: Some IDs may be government issued, valid, and legal but <b>cannot be validated via automatic verification</b>. Uploading these will cause delays in your applicaiton and may require further verification.`,
        BODY_4: `DO NOT UPLOAD THESE KIND.`,
        BODY_5: `Incompatible government issued ID documents:`,
        BODY_5_ITEM_0: `may be plastic laminated`,
        BODY_5_ITEM_1: `use fonts or scripts like "hand-writing" which are not machine readable`,
        BODY_6: `Examples of incompatible government issued IDs:`,
        BODY_6_ITEM_0: `older, laminated Canadian Citizenship card`,
        BODY_6_ITEM_1: `older (pre 2023) POL or PAL firearms licenses`,
        BODY_6_ITEM_2: `Provincial / Territorial, regional or nation / band issued Indian Status cards`,
        BODY_7: `Examples:`,
        BODY_7_IMAGE_ALT: `Example of an out of date and unacceptable Certificate of Canadian Citizenship.`,
        BODY_7_IMAGE_ALT_1: `Example of an unacceptable regionally issued Indian Status card.`,
      },
      MODAL_SCANTIPS: {
        HEADER_TITLE: "ID Scan Tips",
        POPUPINFO:
          "Place your document within the frame until all 4 edges are aligned and the border turns green as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1:
          "Use a contrasting coloured background to help differentiate the edges of your document from its background.",
        POPUPINFO2: "Use a well and evenly lit area.",
        POPUPINFO3: "Ensure all data is readable.",
        POPUPINFO4: "Avoid reflections or glare.",
      },
      MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "Selfie Tips",
        POPUPINFO:
          "Align your full face within the frame as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1: "Use a well and evenly lit area.",
        POPUPINFO2: "Avoid shadowing of your face.",
        POPUPINFO3: "Avoid reflections or glare.",
        POPUPINFO4: `If your selfie looks blurry in the preview, tap "RETAKE".`,
      },
      MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "Success!",
        BODY_0: `Your information has been submitted successfully.`,
        BODY_1: `An email confirmation has been sent to the email address you provided and a representative from ${FIInformation.MoB.en.FI_DISPLAY_NAME} will be in touch with you shortly.`,
      },
      MODAL_LEAVING_APP: {
        HEADER_TITLE: "Leaving so soon?",
        BODY_0: `Leaving now means we will not be able to automatically verify you.`,
        BODY_1: `Are you sure you want to leave now?`,
        CANCEL_CTA: `Cancel`,
        LEAVE_CTA: `Leave now`,
      },
      MONTHS: {
        JAN: `Jan`,
        FEB: `Feb`,
        MAR: `Mar`,
        APR: `Apr`,
        MAY: `May`,
        JUN: `Jun`,
        JUL: `Jul`,
        AUG: `Aug`,
        SEP: `Sep`,
        OCT: `Oct`,
        NOV: `Nov`,
        DEC: `Dec`,
      },
      GENERIC_ERROR_MESSAGE:
        "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
    },
  },
  ArS: {
    en: {
      MAIN: {
        HEADER_TITLE: `Welcome to ${FIInformation.ArS.en.FI_DISPLAY_NAME}'s online identity verification`,
        SUBHEADER_TITLE: `In order to verify your identity you will need:`,
        MOBILE_INFO_CARD: `your mobile device`,
        ID_INFO_CARD: `a valid government-issued identity document`,
        SELFIE_INFO_CARD: `be prepared to take a selfie and photos of your ID`,
        ENVIRONMENT_INFO_CARD: `to be sure you are in a well-lit area`,
        CONTINUE_CTA: `Let's begin`,
      },
      INFO: {
        HEADER_TITLE: `Identity verification`,
        SUBHEADER_TITLE: `Enter your primary email address and mobile number.`,
        EMAIL_LABEL: `Your email address`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `Your mobile no.`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `By continuing you confirm that you have read and agree to the`,
        TERMS_LINK_LABEL_1: `terms and conditions.`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `Continue`,
        ERROR_CODE: {
          EMAIL_REQUIRED: `*Email is required`,
          EMAIL_CHARACTER_LIMIT: `*Email must be less than 100 characters`,
          EMAIL_VALID: `*Must be a valid email address`,
          PHONE_VALID: `*Phone number is not valid`,
          PHONE_REQUIRED: `*Phone number required`,
          TERMS_REQUIRED: `The terms and conditions must be accepted.`,
        },
      },
      VERIFY: {
        HEADER_TITLE: `Mobile phone verification`,
        SUBHEADER_TITLE: `We've sent a verification code to the mobile number you provided. Enter the verification code to continue.`,
        OTP_LABEL: `Verification code`,
        OTP_PLACEHOLDER: `code`,
        RESEND_TITLE: `Didn't receive a code?`,
        RESEND_OTP: `Resend Code`,
        CONTINUE_CTA: `Continue`,
        ERROR: {
          OTP_REQUIRED: `You must enter a verification to continue.`,
          OTP_INLINE_ERROR: `Invalid verification code`,
          ERROR_TITLE: `Error!`,
          ERROR_BODY_0: `The verification code you entered is invalid.`,
          ERROR_BODY_1: `Ensure the code is entered correctly; otherwise, request a new one.`,
        },
      },
      ID_SELECT: {
        HEADER_TITLE: "Select your ID type",
        ID_COMPATIBLE: "Be sure your ID is compatible",
        ID_SELECT_LABEL: "Select your govt. issued ID",
        DUMMY_OPTION: "Select an ID type",
        CONTINUE_CTA: `Continue`,
        SPINNER_MSG: `We need about 10 seconds or so to verify your ID... Please wait!`,
        OVERLAY_FRONT_ID: `Align your ID and tap to capture.`,
        OVERLAY_FRONT_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_BACK_ID: `Align your ID and tap to capture.`,
        OVERLAY_BACK_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_SELFIE: `Align your face and tap to capture.`,
        OVERLAY_SELFIE_AUTOTAKE: `Align your face within the box and hold steady.`,
        VERIFICATION_0: `Verifying your identity... please wait.`,
        VERIFICATION_1: `Validating ID to facial biometrics... please wait.`,
        VERIFICATION_2: `Validating social biometrics... please wait.`,
        ERROR: {
          ERROR_TITLE: `Error!`,
          ERROR_BODY: `Error encountered processing your info...`,
          ERROR_SELECT_ID: `Please select a valid ID type.`,
          ERROR_FRONT_ID: `Please capture the front of your id.`,
          ERROR_BACK_ID: `Please capture the back of your id.`,
          ERROR_SELFIE: `Please capture a selfie.`,
          ERROR_LANDSCAPE: `Landscape orientation is not supported. Rotate your device to a portrait orientation.`,
          ERROR_PORTRAIT: `Portrait orientation is not supported. <br/>Rotate your device to a landscape orientation.`,
        },
      },
      FRONT: {
        TAKE: "Take a photo of the front of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the front of your `,
        RETAKE: "Please take another photo of the front of your ",
        TAKE_PASSPORT: "Take a photo of the main photo page inside of your ",
        TAKE_PASSPORT_CAPTURED_TITLE: "Got it!",
        TAKE_PASSPORT_CAPTURED_SUBTITLE:
          "Below should be a photo of the main page inside of your ",
        RETAKE_PASSPORT:
          "Please re-take the photo of the main photo page inside your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        PROTIP: "Pro Tip",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      BACK: {
        TAKE: "Now take a photo of the back of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the back of your `,
        RETAKE: "Please take another photo of the back of your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      SELFIE: {
        TAKE: "Lastly, take a selfie.",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of you.`,
        RETAKE: "Please take another selfie.",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake selfie? Tap to retake",
        ABOUTYOUINFO: "For best results, take your selfie in a well lit area.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new selfie.`,
        NEXT: "Next",
      },
      REVIEW: {
        SECTION_HEADER_PERSONAL: `Personal`,
        SECTION_HEADER_ADDRESS: `Residential address`,
        CONTINUE_CTA: `Continue`,
        DESKTOP: {
          HEADER_TITLE: `Identity verification`,
          SUBTITLE: `Please enter your details.`,
        },
        MOBILE: {
          HEADER_TITLE: `Thank you`,
          BODY_0: `Please review the information as it was scanned.`,
          BODY_1: `You can update your info should you need to.`,
        },
        FORM: {
          FIRST_NAME_LABEL: `First name`,
          FIRST_NAME_PLACEHOLDER: `First name`,
          MIDDLE_NAME_LABEL: `Middle name`,
          MIDDLE_NAME_PLACEHOLDER: `Middle name`,
          LAST_NAME_LABEL: `Last name`,
          LAST_NAME_PLACEHOLDER: `Last name`,
          DOB_LABEL: `Date of birth`,
          DOB_PLACEHOLDER: `YYYY-MM-DD`,
          STREET_ADDRESS_LABEL: `Street address`,
          STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
          CITY_LABEL: `City`,
          CITY_PLACEHOLDER: `City`,
          PROVINCE_LABEL: `Province / Territory`,
          PROVINCE_PLACEHOLDER: `Province / Territory`,
          POSTAL_CODE_LABEL: `Postal code`,
          POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
          HEADER_TITLE: `Did the information from your ID scan correctly?`,
          YES: `Yes`,
          NO: `No`,
          UPDATE_INFO: `Be sure to update your information so it is correct and current, then hit "Continue".`,
        },
        ERROR_CODE: {
          FIRST_NAME_REQUIRED: `*First name is required`,
          LAST_NAME_REQUIRED: `*Last name is required`,
          DOB_REQUIRED: `*Date of birth is required`,
          ADDRESS_REQUIRED: `*Residential address is required`,
          CITY_REQUIRED: `*City is required`,
          PROVINCE_REQUIRED: `*Province / territory is required`,
          POSTAL_CODE_REQUIRED: `*Postal Code is required`,
          POSTAL_CODE_BAD_FORMAT: `Postal code does not appear to be valid. Please check for errors.`,
          NUMBERS_ERROR: `Numbers and some special characters are not accepted in names.`,
        },
      },
      END: {
        IDENTITY_SUCCESS: {
          HEADER_TITLE: `Success!`,
          BODY_0: `We have received your identification information and a member of the ${FIInformation.ArS.en.FI_DISPLAY_NAME} team will review the details and be in touch with you.`,
          BODY_1: `If you have any questions or concerns, please contact us at`,
          BODY_TEL: `${FIInformation.ArS.en.FI_TEL_PRETTY}`,
          BODY_1_2: `or`,
          BODY_EMAIL: `${FIInformation.ArS.en.FI_EMAIL_PRETTY}.`,
          FOOTER: `You can now close this browser window.`,
        },
        IDENTITY_FAIL: {
          HEADER_TITLE: `Hmm...`,
          BODY_0: `Unfortunately we were unable to automatically verify you at this time.`,
          BODY_1: `A confirmation email has been sent to your email address. A representative from ${FIInformation.ArS.en.FI_DISPLAY_NAME} will reach out to you soon.`,
          FOOTER: `You can now close this browser window.`,
        },
        LEAVING: {
          HEADER_TITLE: `Leaving us?`,
          BODY_0: `You are now finished with automatic verification. Any information you may have entered has been removed from our systems.`,
          FOOTER: `You can now close this browser window.`,
        },
      },
      MODAL: {
        CLOSE: "Close",
        OK: "OK",
      },
      MODAL_COMPATIBLE: {
        HEADER_TITLE: `ID Compatability Check`,
        BODY_0: `Our system uses machine reading and AI technologies to authentic ID documents. To prevent application delays, please upload government issued ID documents compatible with our system.`,
        BODY_1: `Compatible government issued ID documents have:`,
        BODY_1_ITEM_0: `your photo`,
        BODY_1_ITEM_1: `your date of birth`,
        BODY_1_ITEM_2: `machine readable fonts`,
        BODY_1_ITEM_3: `a bar code`,
        BODY_2: `Examples of compatible government issued IDs:`,
        BODY_2_ITEM_0: `Provincial / Territorial Issued Enhanced Driver's License`,
        BODY_2_ITEM_1: `Provincial / Territorial Issued Photo ID card`,
        BODY_2_ITEM_2: `Federally issued Secure Certificate of Indian Status`,
        BODY_2_ITEM_3: `Canadian Citizenship card`,
        BODY_2_ITEM_4: `Passport`,
        BODY_2_ITEM_5: `Candaian Forces Identification cards`,
        BODY_2_ITEM_6: `Permanent Resident card`,
        BODY_2_ITEM_7: `New (2023) Provincial / Territorial Issued PAL Firearms License`,
        BODY_2_ITEM_8: `USA/CAN Nexus card`,
        BODY_3_IMAGE_HEADER: `Example:`,
        BODY_3_IMAGE_ALT: `Example of a federally issued Certificate of Indian Status card.`,
        BODY_3: `NOTE: Some IDs may be government issued, valid, and legal but <b>cannot be validated via automatic verification</b>. Uploading these will cause delays in your applicaiton and may require further verification.`,
        BODY_4: `DO NOT UPLOAD THESE KIND.`,
        BODY_5: `Incompatible government issued ID documents:`,
        BODY_5_ITEM_0: `may be plastic laminated`,
        BODY_5_ITEM_1: `use fonts or scripts like "hand-writing" which are not machine readable`,
        BODY_6: `Examples of incompatible government issued IDs:`,
        BODY_6_ITEM_0: `older, laminated Canadian Citizenship card`,
        BODY_6_ITEM_1: `older (pre 2023) POL or PAL firearms licenses`,
        BODY_6_ITEM_2: `Provincial / Territorial, regional or nation / band issued Indian Status cards`,
        BODY_7: `Examples:`,
        BODY_7_IMAGE_ALT: `Example of an out of date and unacceptable Certificate of Canadian Citizenship.`,
        BODY_7_IMAGE_ALT_1: `Example of an unacceptable regionally issued Indian Status card.`,
      },
      MODAL_SCANTIPS: {
        HEADER_TITLE: "ID Scan Tips",
        POPUPINFO:
          "Place your document within the frame until all 4 edges are aligned and the border turns green as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1:
          "Use a contrasting coloured background to help differentiate the edges of your document from its background.",
        POPUPINFO2: "Use a well and evenly lit area.",
        POPUPINFO3: "Ensure all data is readable.",
        POPUPINFO4: "Avoid reflections or glare.",
      },
      MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "Selfie Tips",
        POPUPINFO:
          "Align your full face within the frame as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1: "Use a well and evenly lit area.",
        POPUPINFO2: "Avoid shadowing of your face.",
        POPUPINFO3: "Avoid reflections or glare.",
        POPUPINFO4: `If your selfie looks blurry in the preview, tap "RETAKE".`,
      },
      MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "Success!",
        BODY_0: `Your information has been submitted successfully.`,
        BODY_1: `An email confirmation has been sent to the email address you provided and a representative from ${FIInformation.ArS.en.FI_DISPLAY_NAME} will be in touch with you shortly.`,
      },
      MODAL_LEAVING_APP: {
        HEADER_TITLE: "Leaving so soon?",
        BODY_0: `Leaving now means we will not be able to automatically verify you.`,
        BODY_1: `Are you sure you want to leave now?`,
        CANCEL_CTA: `Cancel`,
        LEAVE_CTA: `Leave now`,
      },
      MONTHS: {
        JAN: `Jan`,
        FEB: `Feb`,
        MAR: `Mar`,
        APR: `Apr`,
        MAY: `May`,
        JUN: `Jun`,
        JUL: `Jul`,
        AUG: `Aug`,
        SEP: `Sep`,
        OCT: `Oct`,
        NOV: `Nov`,
        DEC: `Dec`,
      },
      GENERIC_ERROR_MESSAGE:
        "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
    },
  },
  CoS: {
    en: {
      MAIN: {
        HEADER_TITLE: `Welcome to ${FIInformation.CoS.en.FI_DISPLAY_NAME}'s online identity verification`,
        SUBHEADER_TITLE: `In order to verify your identity you will need:`,
        MOBILE_INFO_CARD: `your mobile device`,
        ID_INFO_CARD: `a valid government-issued identity document`,
        SELFIE_INFO_CARD: `be prepared to take a selfie and photos of your ID`,
        ENVIRONMENT_INFO_CARD: `to be sure you are in a well-lit area`,
        CONTINUE_CTA: `Let's begin`,
      },
      INFO: {
        HEADER_TITLE: `Identity verification`,
        SUBHEADER_TITLE: `Enter your primary email address and mobile number.`,
        EMAIL_LABEL: `Your email address`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `Your mobile no.`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `By continuing you confirm that you have read and agree to the`,
        TERMS_LINK_LABEL_1: `terms and conditions.`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `Continue`,
        ERROR_CODE: {
          EMAIL_REQUIRED: `*Email is required`,
          EMAIL_CHARACTER_LIMIT: `*Email must be less than 100 characters`,
          EMAIL_VALID: `*Must be a valid email address`,
          PHONE_VALID: `*Phone number is not valid`,
          PHONE_REQUIRED: `*Phone number required`,
          TERMS_REQUIRED: `The terms and conditions must be accepted.`,
        },
      },
      VERIFY: {
        HEADER_TITLE: `Mobile phone verification`,
        SUBHEADER_TITLE: `We've sent a verification code to the mobile number you provided. Enter the verification code to continue.`,
        OTP_LABEL: `Verification code`,
        OTP_PLACEHOLDER: `code`,
        RESEND_TITLE: `Didn't receive a code?`,
        RESEND_OTP: `Resend Code`,
        CONTINUE_CTA: `Continue`,
        ERROR: {
          OTP_REQUIRED: `You must enter a verification to continue.`,
          OTP_INLINE_ERROR: `Invalid verification code`,
          ERROR_TITLE: `Error!`,
          ERROR_BODY_0: `The verification code you entered is invalid.`,
          ERROR_BODY_1: `Ensure the code is entered correctly; otherwise, request a new one.`,
        },
      },
      ID_SELECT: {
        HEADER_TITLE: "Select your ID type",
        ID_COMPATIBLE: "Be sure your ID is compatible",
        ID_SELECT_LABEL: "Select your govt. issued ID",
        DUMMY_OPTION: "Select an ID type",
        CONTINUE_CTA: `Continue`,
        SPINNER_MSG: `We need about 10 seconds or so to verify your ID... Please wait!`,
        OVERLAY_FRONT_ID: `Align your ID and tap to capture.`,
        OVERLAY_FRONT_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_BACK_ID: `Align your ID and tap to capture.`,
        OVERLAY_BACK_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_SELFIE: `Align your face and tap to capture.`,
        OVERLAY_SELFIE_AUTOTAKE: `Align your face within the box and hold steady.`,
        VERIFICATION_0: `Verifying your identity... please wait.`,
        VERIFICATION_1: `Validating ID to facial biometrics... please wait.`,
        VERIFICATION_2: `Validating social biometrics... please wait.`,
        ERROR: {
          ERROR_TITLE: `Error!`,
          ERROR_BODY: `Error encountered processing your info...`,
          ERROR_SELECT_ID: `Please select a valid ID type.`,
          ERROR_FRONT_ID: `Please capture the front of your id.`,
          ERROR_BACK_ID: `Please capture the back of your id.`,
          ERROR_SELFIE: `Please capture a selfie.`,
          ERROR_LANDSCAPE: `Landscape orientation is not supported. Rotate your device to a portrait orientation.`,
          ERROR_PORTRAIT: `Portrait orientation is not supported. <br/>Rotate your device to a landscape orientation.`,
        },
      },
      FRONT: {
        TAKE: "Take a photo of the front of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the front of your `,
        RETAKE: "Please take another photo of the front of your ",
        TAKE_PASSPORT: "Take a photo of the main photo page inside of your ",
        TAKE_PASSPORT_CAPTURED_TITLE: "Got it!",
        TAKE_PASSPORT_CAPTURED_SUBTITLE:
          "Below should be a photo of the main page inside of your ",
        RETAKE_PASSPORT:
          "Please re-take the photo of the main photo page inside your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        PROTIP: "Pro Tip",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      BACK: {
        TAKE: "Now take a photo of the back of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the back of your `,
        RETAKE: "Please take another photo of the back of your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      SELFIE: {
        TAKE: "Lastly, take a selfie.",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of you.`,
        RETAKE: "Please take another selfie.",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake selfie? Tap to retake",
        ABOUTYOUINFO: "For best results, take your selfie in a well lit area.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new selfie.`,
        NEXT: "Next",
      },
      REVIEW: {
        SECTION_HEADER_PERSONAL: `Personal`,
        SECTION_HEADER_ADDRESS: `Residential address`,
        CONTINUE_CTA: `Continue`,
        DESKTOP: {
          HEADER_TITLE: `Identity verification`,
          SUBTITLE: `Please enter your details.`,
        },
        MOBILE: {
          HEADER_TITLE: `Thank you`,
          BODY_0: `Please review the information as it was scanned.`,
          BODY_1: `You can update your info should you need to.`,
        },
        FORM: {
          FIRST_NAME_LABEL: `First name`,
          FIRST_NAME_PLACEHOLDER: `First name`,
          MIDDLE_NAME_LABEL: `Middle name`,
          MIDDLE_NAME_PLACEHOLDER: `Middle name`,
          LAST_NAME_LABEL: `Last name`,
          LAST_NAME_PLACEHOLDER: `Last name`,
          DOB_LABEL: `Date of birth`,
          DOB_PLACEHOLDER: `YYYY-MM-DD`,
          STREET_ADDRESS_LABEL: `Street address`,
          STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
          CITY_LABEL: `City`,
          CITY_PLACEHOLDER: `City`,
          PROVINCE_LABEL: `Province / Territory`,
          PROVINCE_PLACEHOLDER: `Province / Territory`,
          POSTAL_CODE_LABEL: `Postal code`,
          POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
          HEADER_TITLE: `Did the information from your ID scan correctly?`,
          YES: `Yes`,
          NO: `No`,
          UPDATE_INFO: `Be sure to update your information so it is correct and current, then hit "Continue".`,
        },
        ERROR_CODE: {
          FIRST_NAME_REQUIRED: `*First name is required`,
          LAST_NAME_REQUIRED: `*Last name is required`,
          DOB_REQUIRED: `*Date of birth is required`,
          ADDRESS_REQUIRED: `*Residential address is required`,
          CITY_REQUIRED: `*City is required`,
          PROVINCE_REQUIRED: `*Province / territory is required`,
          POSTAL_CODE_REQUIRED: `*Postal Code is required`,
          POSTAL_CODE_BAD_FORMAT: `Postal code does not appear to be valid. Please check for errors.`,
          NUMBERS_ERROR: `Numbers and some special characters are not accepted in names.`,
        },
      },
      END: {
        IDENTITY_SUCCESS: {
          HEADER_TITLE: `Success!`,
          BODY_0: `We have received your identification information and a member of the ${FIInformation.CoS.en.FI_DISPLAY_NAME} team will review the details and be in touch with you.`,
          BODY_1: `If you have any questions or concerns, please contact us at`,
          BODY_TEL: `${FIInformation.CoS.en.FI_TEL_PRETTY}`,
          BODY_1_2: `or`,
          BODY_EMAIL: `${FIInformation.CoS.en.FI_EMAIL_PRETTY}.`,
          FOOTER: `You can now close this browser window.`,
        },
        IDENTITY_FAIL: {
          HEADER_TITLE: `Hmm...`,
          BODY_0: `Unfortunately we were unable to automatically verify you at this time.`,
          BODY_1: `A confirmation email has been sent to your email address. A representative from ${FIInformation.CoS.en.FI_DISPLAY_NAME} will reach out to you soon.`,
          FOOTER: `You can now close this browser window.`,
        },
        LEAVING: {
          HEADER_TITLE: `Leaving us?`,
          BODY_0: `You are now finished with automatic verification. Any information you may have entered has been removed from our systems.`,
          FOOTER: `You can now close this browser window.`,
        },
      },
      MODAL: {
        CLOSE: "Close",
        OK: "OK",
      },
      MODAL_COMPATIBLE: {
        HEADER_TITLE: `ID Compatability Check`,
        BODY_0: `Our system uses machine reading and AI technologies to authentic ID documents. To prevent application delays, please upload government issued ID documents compatible with our system.`,
        BODY_1: `Compatible government issued ID documents have:`,
        BODY_1_ITEM_0: `your photo`,
        BODY_1_ITEM_1: `your date of birth`,
        BODY_1_ITEM_2: `machine readable fonts`,
        BODY_1_ITEM_3: `a bar code`,
        BODY_2: `Examples of compatible government issued IDs:`,
        BODY_2_ITEM_0: `Provincial / Territorial Issued Enhanced Driver's License`,
        BODY_2_ITEM_1: `Provincial / Territorial Issued Photo ID card`,
        BODY_2_ITEM_2: `Federally issued Secure Certificate of Indian Status`,
        BODY_2_ITEM_3: `Canadian Citizenship card`,
        BODY_2_ITEM_4: `Passport`,
        BODY_2_ITEM_5: `Candaian Forces Identification cards`,
        BODY_2_ITEM_6: `Permanent Resident card`,
        BODY_2_ITEM_7: `New (2023) Provincial / Territorial Issued PAL Firearms License`,
        BODY_2_ITEM_8: `USA/CAN Nexus card`,
        BODY_3_IMAGE_HEADER: `Example:`,
        BODY_3_IMAGE_ALT: `Example of a federally issued Certificate of Indian Status card.`,
        BODY_3: `NOTE: Some IDs may be government issued, valid, and legal but <b>cannot be validated via automatic verification</b>. Uploading these will cause delays in your applicaiton and may require further verification.`,
        BODY_4: `DO NOT UPLOAD THESE KIND.`,
        BODY_5: `Incompatible government issued ID documents:`,
        BODY_5_ITEM_0: `may be plastic laminated`,
        BODY_5_ITEM_1: `use fonts or scripts like "hand-writing" which are not machine readable`,
        BODY_6: `Examples of incompatible government issued IDs:`,
        BODY_6_ITEM_0: `older, laminated Canadian Citizenship card`,
        BODY_6_ITEM_1: `older (pre 2023) POL or PAL firearms licenses`,
        BODY_6_ITEM_2: `Provincial / Territorial, regional or nation / band issued Indian Status cards`,
        BODY_7: `Examples:`,
        BODY_7_IMAGE_ALT: `Example of an out of date and unacceptable Certificate of Canadian Citizenship.`,
        BODY_7_IMAGE_ALT_1: `Example of an unacceptable regionally issued Indian Status card.`,
      },
      MODAL_SCANTIPS: {
        HEADER_TITLE: "ID Scan Tips",
        POPUPINFO:
          "Place your document within the frame until all 4 edges are aligned and the border turns green as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1:
          "Use a contrasting coloured background to help differentiate the edges of your document from its background.",
        POPUPINFO2: "Use a well and evenly lit area.",
        POPUPINFO3: "Ensure all data is readable.",
        POPUPINFO4: "Avoid reflections or glare.",
      },
      MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "Selfie Tips",
        POPUPINFO:
          "Align your full face within the frame as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1: "Use a well and evenly lit area.",
        POPUPINFO2: "Avoid shadowing of your face.",
        POPUPINFO3: "Avoid reflections or glare.",
        POPUPINFO4: `If your selfie looks blurry in the preview, tap "RETAKE".`,
      },
      MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "Success!",
        BODY_0: `Your information has been submitted successfully.`,
        BODY_1: `An email confirmation has been sent to the email address you provided and a representative from ${FIInformation.CoS.en.FI_DISPLAY_NAME} will be in touch with you shortly.`,
      },
      MODAL_LEAVING_APP: {
        HEADER_TITLE: "Leaving so soon?",
        BODY_0: `Leaving now means we will not be able to automatically verify you.`,
        BODY_1: `Are you sure you want to leave now?`,
        CANCEL_CTA: `Cancel`,
        LEAVE_CTA: `Leave now`,
      },
      MONTHS: {
        JAN: `Jan`,
        FEB: `Feb`,
        MAR: `Mar`,
        APR: `Apr`,
        MAY: `May`,
        JUN: `Jun`,
        JUL: `Jul`,
        AUG: `Aug`,
        SEP: `Sep`,
        OCT: `Oct`,
        NOV: `Nov`,
        DEC: `Dec`,
      },
      GENERIC_ERROR_MESSAGE:
        "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
    },
  },
  AnS: {
    en: {
      MAIN: {
        HEADER_TITLE: `Welcome to ${FIInformation.AnS.en.FI_DISPLAY_NAME}'s online identity verification`,
        SUBHEADER_TITLE: `In order to verify your identity you will need:`,
        MOBILE_INFO_CARD: `your mobile device`,
        ID_INFO_CARD: `a valid government-issued identity document`,
        SELFIE_INFO_CARD: `be prepared to take a selfie and photos of your ID`,
        ENVIRONMENT_INFO_CARD: `to be sure you are in a well-lit area`,
        CONTINUE_CTA: `Let's begin`,
      },
      INFO: {
        HEADER_TITLE: `Identity verification`,
        SUBHEADER_TITLE: `Enter your primary email address and mobile number.`,
        EMAIL_LABEL: `Your email address`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `Your mobile no.`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `By continuing you confirm that you have read and agree to the`,
        TERMS_LINK_LABEL_1: `terms and conditions.`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `Continue`,
        ERROR_CODE: {
          EMAIL_REQUIRED: `*Email is required`,
          EMAIL_CHARACTER_LIMIT: `*Email must be less than 100 characters`,
          EMAIL_VALID: `*Must be a valid email address`,
          PHONE_VALID: `*Phone number is not valid`,
          PHONE_REQUIRED: `*Phone number required`,
          TERMS_REQUIRED: `The terms and conditions must be accepted.`,
        },
      },
      VERIFY: {
        HEADER_TITLE: `Mobile phone verification`,
        SUBHEADER_TITLE: `We've sent a verification code to the mobile number you provided. Enter the verification code to continue.`,
        OTP_LABEL: `Verification code`,
        OTP_PLACEHOLDER: `code`,
        RESEND_TITLE: `Didn't receive a code?`,
        RESEND_OTP: `Resend Code`,
        CONTINUE_CTA: `Continue`,
        ERROR: {
          OTP_REQUIRED: `You must enter a verification to continue.`,
          OTP_INLINE_ERROR: `Invalid verification code`,
          ERROR_TITLE: `Error!`,
          ERROR_BODY_0: `The verification code you entered is invalid.`,
          ERROR_BODY_1: `Ensure the code is entered correctly; otherwise, request a new one.`,
        },
      },
      ID_SELECT: {
        HEADER_TITLE: "Select your ID type",
        ID_COMPATIBLE: "Be sure your ID is compatible",
        ID_SELECT_LABEL: "Select your govt. issued ID",
        DUMMY_OPTION: "Select an ID type",
        CONTINUE_CTA: `Continue`,
        SPINNER_MSG: `We need about 10 seconds or so to verify your ID... Please wait!`,
        OVERLAY_FRONT_ID: `Align your ID and tap to capture.`,
        OVERLAY_FRONT_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_BACK_ID: `Align your ID and tap to capture.`,
        OVERLAY_BACK_ID_AUTOTAKE: `Align your ID within the box and hold steady.`,
        OVERLAY_SELFIE: `Align your face and tap to capture.`,
        OVERLAY_SELFIE_AUTOTAKE: `Align your face within the box and hold steady.`,
        VERIFICATION_0: `Verifying your identity... please wait.`,
        VERIFICATION_1: `Validating ID to facial biometrics... please wait.`,
        VERIFICATION_2: `Validating social biometrics... please wait.`,
        ERROR: {
          ERROR_TITLE: `Error!`,
          ERROR_BODY: `Error encountered processing your info...`,
          ERROR_SELECT_ID: `Please select a valid ID type.`,
          ERROR_FRONT_ID: `Please capture the front of your id.`,
          ERROR_BACK_ID: `Please capture the back of your id.`,
          ERROR_SELFIE: `Please capture a selfie.`,
          ERROR_LANDSCAPE: `Landscape orientation is not supported. Rotate your device to a portrait orientation.`,
          ERROR_PORTRAIT: `Portrait orientation is not supported. <br/>Rotate your device to a landscape orientation.`,
        },
      },
      FRONT: {
        TAKE: "Take a photo of the front of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the front of your `,
        RETAKE: "Please take another photo of the front of your ",
        TAKE_PASSPORT: "Take a photo of the main photo page inside of your ",
        TAKE_PASSPORT_CAPTURED_TITLE: "Got it!",
        TAKE_PASSPORT_CAPTURED_SUBTITLE:
          "Below should be a photo of the main page inside of your ",
        RETAKE_PASSPORT:
          "Please re-take the photo of the main photo page inside your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        PROTIP: "Pro Tip",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      BACK: {
        TAKE: "Now take a photo of the back of your ",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of the back of your `,
        RETAKE: "Please take another photo of the back of your ",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake? Tap to retake",
        NEXT: "Next",
        ABOUTYOUINFO:
          "For best results, scan your ID in a well lit area against a contrasting background.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new picture.`,
      },
      SELFIE: {
        TAKE: "Lastly, take a selfie.",
        TAKE_CAPTURED_TITLE: `Got it!`,
        TAKE_CAPTURED_SUBTITLE: `Below should be a photo of you.`,
        RETAKE: "Please take another selfie.",
        TAP_BEGIN: "Tap to begin",
        TAP_RETAKE: "Retake selfie? Tap to retake",
        ABOUTYOUINFO: "For best results, take your selfie in a well lit area.",
        ABOUTYOUINFO_CAPTURED: `Check the thumbnail. Is the image quality acceptable?`,
        ABOUTYOUINFO1: "See more tips.",
        ABOUTYOUINFO1_CAPTURED: `If not, tap "Retake" to take a new selfie.`,
        NEXT: "Next",
      },
      REVIEW: {
        SECTION_HEADER_PERSONAL: `Personal`,
        SECTION_HEADER_ADDRESS: `Residential address`,
        CONTINUE_CTA: `Continue`,
        DESKTOP: {
          HEADER_TITLE: `Identity verification`,
          SUBTITLE: `Please enter your details.`,
        },
        MOBILE: {
          HEADER_TITLE: `Thank you`,
          BODY_0: `Please review the information as it was scanned.`,
          BODY_1: `You can update your info should you need to.`,
        },
        FORM: {
          FIRST_NAME_LABEL: `First name`,
          FIRST_NAME_PLACEHOLDER: `First name`,
          MIDDLE_NAME_LABEL: `Middle name`,
          MIDDLE_NAME_PLACEHOLDER: `Middle name`,
          LAST_NAME_LABEL: `Last name`,
          LAST_NAME_PLACEHOLDER: `Last name`,
          DOB_LABEL: `Date of birth`,
          DOB_PLACEHOLDER: `YYYY-MM-DD`,
          STREET_ADDRESS_LABEL: `Street address`,
          STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
          CITY_LABEL: `City`,
          CITY_PLACEHOLDER: `City`,
          PROVINCE_LABEL: `Province / Territory`,
          PROVINCE_PLACEHOLDER: `Province / Territory`,
          POSTAL_CODE_LABEL: `Postal code`,
          POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
          HEADER_TITLE: `Did the information from your ID scan correctly?`,
          YES: `Yes`,
          NO: `No`,
          UPDATE_INFO: `Be sure to update your information so it is correct and current, then hit "Continue".`,
        },
        ERROR_CODE: {
          FIRST_NAME_REQUIRED: `*First name is required`,
          LAST_NAME_REQUIRED: `*Last name is required`,
          DOB_REQUIRED: `*Date of birth is required`,
          ADDRESS_REQUIRED: `*Residential address is required`,
          CITY_REQUIRED: `*City is required`,
          PROVINCE_REQUIRED: `*Province / territory is required`,
          POSTAL_CODE_REQUIRED: `*Postal Code is required`,
          POSTAL_CODE_BAD_FORMAT: `Postal code does not appear to be valid. Please check for errors.`,
          NUMBERS_ERROR: `Numbers and some special characters are not accepted in names.`,
        },
      },
      END: {
        IDENTITY_SUCCESS: {
          HEADER_TITLE: `Success!`,
          BODY_0: `We have received your identification information and a member of the ${FIInformation.AnS.en.FI_DISPLAY_NAME} team will review the details and be in touch with you.`,
          BODY_1: `If you have any questions or concerns, please contact us at`,
          BODY_TEL: `${FIInformation.AnS.en.FI_TEL_PRETTY}`,
          BODY_1_2: `or`,
          BODY_EMAIL: `${FIInformation.AnS.en.FI_EMAIL_PRETTY}.`,
          FOOTER: `You can now close this browser window.`,
        },
        IDENTITY_FAIL: {
          HEADER_TITLE: `Hmm...`,
          BODY_0: `Unfortunately we were unable to automatically verify you at this time.`,
          BODY_1: `A confirmation email has been sent to your email address. A representative from ${FIInformation.AnS.en.FI_DISPLAY_NAME} will reach out to you soon.`,
          FOOTER: `You can now close this browser window.`,
        },
        LEAVING: {
          HEADER_TITLE: `Leaving us?`,
          BODY_0: `You are now finished with automatic verification. Any information you may have entered has been removed from our systems.`,
          FOOTER: `You can now close this browser window.`,
        },
      },
      MODAL: {
        CLOSE: "Close",
        OK: "OK",
      },
      MODAL_COMPATIBLE: {
        HEADER_TITLE: `ID Compatability Check`,
        BODY_0: `Our system uses machine reading and AI technologies to authentic ID documents. To prevent application delays, please upload government issued ID documents compatible with our system.`,
        BODY_1: `Compatible government issued ID documents have:`,
        BODY_1_ITEM_0: `your photo`,
        BODY_1_ITEM_1: `your date of birth`,
        BODY_1_ITEM_2: `machine readable fonts`,
        BODY_1_ITEM_3: `a bar code`,
        BODY_2: `Examples of compatible government issued IDs:`,
        BODY_2_ITEM_0: `Provincial / Territorial Issued Enhanced Driver's License`,
        BODY_2_ITEM_1: `Provincial / Territorial Issued Photo ID card`,
        BODY_2_ITEM_2: `Federally issued Secure Certificate of Indian Status`,
        BODY_2_ITEM_3: `Canadian Citizenship card`,
        BODY_2_ITEM_4: `Passport`,
        BODY_2_ITEM_5: `Candaian Forces Identification cards`,
        BODY_2_ITEM_6: `Permanent Resident card`,
        BODY_2_ITEM_7: `New (2023) Provincial / Territorial Issued PAL Firearms License`,
        BODY_2_ITEM_8: `USA/CAN Nexus card`,
        BODY_3_IMAGE_HEADER: `Example:`,
        BODY_3_IMAGE_ALT: `Example of a federally issued Certificate of Indian Status card.`,
        BODY_3: `NOTE: Some IDs may be government issued, valid, and legal but <b>cannot be validated via automatic verification</b>. Uploading these will cause delays in your applicaiton and may require further verification.`,
        BODY_4: `DO NOT UPLOAD THESE KIND.`,
        BODY_5: `Incompatible government issued ID documents:`,
        BODY_5_ITEM_0: `may be plastic laminated`,
        BODY_5_ITEM_1: `use fonts or scripts like "hand-writing" which are not machine readable`,
        BODY_6: `Examples of incompatible government issued IDs:`,
        BODY_6_ITEM_0: `older, laminated Canadian Citizenship card`,
        BODY_6_ITEM_1: `older (pre 2023) POL or PAL firearms licenses`,
        BODY_6_ITEM_2: `Provincial / Territorial, regional or nation / band issued Indian Status cards`,
        BODY_7: `Examples:`,
        BODY_7_IMAGE_ALT: `Example of an out of date and unacceptable Certificate of Canadian Citizenship.`,
        BODY_7_IMAGE_ALT_1: `Example of an unacceptable regionally issued Indian Status card.`,
      },
      MODAL_SCANTIPS: {
        HEADER_TITLE: "ID Scan Tips",
        POPUPINFO:
          "Place your document within the frame until all 4 edges are aligned and the border turns green as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1:
          "Use a contrasting coloured background to help differentiate the edges of your document from its background.",
        POPUPINFO2: "Use a well and evenly lit area.",
        POPUPINFO3: "Ensure all data is readable.",
        POPUPINFO4: "Avoid reflections or glare.",
      },
      MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "Selfie Tips",
        POPUPINFO:
          "Align your full face within the frame as it is shown in the animation above. The capturing usually happens automatically.",
        POPUPINFO1: "Use a well and evenly lit area.",
        POPUPINFO2: "Avoid shadowing of your face.",
        POPUPINFO3: "Avoid reflections or glare.",
        POPUPINFO4: `If your selfie looks blurry in the preview, tap "RETAKE".`,
      },
      MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "Success!",
        BODY_0: `Your information has been submitted successfully.`,
        BODY_1: `An email confirmation has been sent to the email address you provided and a representative from ${FIInformation.AnS.en.FI_DISPLAY_NAME} will be in touch with you shortly.`,
      },
      MODAL_LEAVING_APP: {
        HEADER_TITLE: "Leaving so soon?",
        BODY_0: `Leaving now means we will not be able to automatically verify you.`,
        BODY_1: `Are you sure you want to leave now?`,
        CANCEL_CTA: `Cancel`,
        LEAVE_CTA: `Leave now`,
      },
      MONTHS: {
        JAN: `Jan`,
        FEB: `Feb`,
        MAR: `Mar`,
        APR: `Apr`,
        MAY: `May`,
        JUN: `Jun`,
        JUL: `Jul`,
        AUG: `Aug`,
        SEP: `Sep`,
        OCT: `Oct`,
        NOV: `Nov`,
        DEC: `Dec`,
      },
      GENERIC_ERROR_MESSAGE:
        "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
    },
    cs: {
      MAIN: {
        HEADER_TITLE: `欢迎使用 安信 的在线身份验证系统`,
        SUBHEADER_TITLE: `为验证您的身份，您需要:`,
        MOBILE_INFO_CARD: `您的手机`,
        ID_INFO_CARD: `政府签发的有效身份证件`,
        SELFIE_INFO_CARD: `请准备好自拍和拍摄您的身份证件`,
        ENVIRONMENT_INFO_CARD: `请确保您位于光线充足的地方`,
        CONTINUE_CTA: `让我们开始吧`,
      },
      INFO: {
        HEADER_TITLE: `身份验证`,
        SUBHEADER_TITLE: `请输入您的主要邮箱和手机号码。`,
        EMAIL_LABEL: `您的邮箱`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `您的手机号码`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `点击继续即表示您确认已阅读并同意`,
        TERMS_LINK_LABEL_1: `条款和条件`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `继续`,
        ERROR_CODE: {
          EMAIL_REQUIRED: `邮箱是必填项`,
          EMAIL_CHARACTER_LIMIT: `电子邮件必须少于100个字符`,
          EMAIL_VALID: `必须是有效的电子邮件地址`,
          PHONE_VALID: `电话号码无效`,
          PHONE_REQUIRED: `电话号码是必填项`,
          TERMS_REQUIRED: `必须接受的条款和条件`,
        },
      },
      VERIFY: {
        HEADER_TITLE: `手机验证`,
        SUBHEADER_TITLE: `我们已向您提供的手机号码发送了验证码。请输入验证码并继续。`,
        OTP_LABEL: `验证码`,
        OTP_PLACEHOLDER: `验证码`,
        RESEND_TITLE: `未收到验证码？`,
        RESEND_OTP: `重新发送代码`,
        CONTINUE_CTA: `继续`,
        ERROR: {
          OTP_REQUIRED: `您必须输入验证码才能继续。`,
          OTP_INLINE_ERROR: `无效的验证码`,
          ERROR_TITLE: `错误`,
          ERROR_BODY_0: `您输入的验证码无效`,
          ERROR_BODY_1: `请确保代码输入正确；或者申请一个新的代码`,
        },
      },
      ID_SELECT: {
        HEADER_TITLE: "请选择ID类型",
        ID_COMPATIBLE: "请确保您的ID 兼容",
        ID_SELECT_LABEL: "请选择政府签发的ID",
        DUMMY_OPTION: "请选择ID类型",
        CONTINUE_CTA: `继续`,
        SPINNER_MSG: `我们需要大约 10 秒左右来验证您的 ID...请稍候！`,
        OVERLAY_FRONT_ID: `请对准您的 ID 并点击拍摄`,
        OVERLAY_FRONT_ID_AUTOTAKE: `请将您的 ID 放在边框中对齐并保持稳定`,
        OVERLAY_BACK_ID: `请对准您的 ID 并点击拍摄`,
        OVERLAY_BACK_ID_AUTOTAKE: `请将您的 ID 放在边框中对齐并保持稳定`,
        OVERLAY_SELFIE: `请对准面部并点击拍摄`,
        OVERLAY_SELFIE_AUTOTAKE: `请将您的面部对准方框并保持稳定`,
        VERIFICATION_0: `正在验证您的身份...请稍候`,
        VERIFICATION_1: `正在验证ID进行面部生物识别...请稍候`,
        VERIFICATION_2: `正在验证社交生物识别信息...请稍候`,
        ERROR: {
          ERROR_TITLE: `错误`,
          ERROR_BODY: `处理您的信息时遇到错误…`,
          ERROR_SELECT_ID: `请选择有效的ID类型`,
          ERROR_FRONT_ID: `请拍摄您的身份证件正面`,
          ERROR_BACK_ID: `请拍摄您的身份证件背面`,
          ERROR_SELFIE: `请拍摄一张自拍照`,
          ERROR_LANDSCAPE: `不支持横向，将设备旋转至纵向`,
          ERROR_PORTRAIT: `不支持纵向， 将设备旋转至横向`,
        },
      },
      FRONT: {
        TAKE: "请拍摄您的正面照片 ",
        TAKE_CAPTURED_TITLE: `拍摄成功！`,
        TAKE_CAPTURED_SUBTITLE: `下面是您正面的照片 `,
        RETAKE: "请再拍摄一张您的正面照片 ",
        TAKE_PASSPORT: "请拍摄一张主页照片 ",
        TAKE_PASSPORT_CAPTURED_TITLE: "拍摄成功",
        TAKE_PASSPORT_CAPTURED_SUBTITLE: "下面是您的主页照片 ",
        RETAKE_PASSPORT: "请重新拍摄您的主页照片 ",
        TAP_BEGIN: "点击开始",
        TAP_RETAKE: "需要重新拍摄吗？点击即可重新拍摄",
        NEXT: "下一步",
        PROTIP: "专业提示",
        ABOUTYOUINFO:
          "为获得最佳效果，请在光线充足、背景对比鲜明的地方扫描身份证件",
        ABOUTYOUINFO_CAPTURED: `检查缩略图。图像质量是否满意？`,
        ABOUTYOUINFO1: "查看更多提示",
        ABOUTYOUINFO1_CAPTURED: `如果不满意，请点击“重新拍摄”拍摄新照片`,
      },
      BACK: {
        TAKE: "现在拍一张您的背面照片 ",
        TAKE_CAPTURED_TITLE: `拍摄成功！`,
        TAKE_CAPTURED_SUBTITLE: `下面是您的背面照片 `,
        RETAKE: "请再拍一张您的背面照片 ",
        TAP_BEGIN: "点击开始",
        TAP_RETAKE: "需要重新拍摄吗？点击即可重新拍摄",
        NEXT: "下一步",
        ABOUTYOUINFO:
          "为获得最佳效果，请在光线充足、背景对比鲜明的地方扫描您的身份证件",
        ABOUTYOUINFO_CAPTURED: `检查缩略图。图像质量是否满意？`,
        ABOUTYOUINFO1: "查看更多提示",
        ABOUTYOUINFO1_CAPTURED: `如果不满意，请点击“重新拍摄”拍摄新照片`,
      },
      SELFIE: {
        TAKE: "最后来拍一张自拍吧",
        TAKE_CAPTURED_TITLE: `拍摄成功！`,
        TAKE_CAPTURED_SUBTITLE: `下面是您的照片`,
        RETAKE: "再拍一张自拍照",
        TAP_BEGIN: "点击开始",
        TAP_RETAKE: "想要重新自拍？点击重拍",
        ABOUTYOUINFO: "为了获得最佳效果，请在光线充足的地方进行自拍",
        ABOUTYOUINFO_CAPTURED: `检查缩略图。图像质量是否满意？`,
        ABOUTYOUINFO1: "查看更多提示",
        ABOUTYOUINFO1_CAPTURED: `如果不满意，请点击“重新拍摄”拍摄新的自拍照`,
        NEXT: "下一步",
      },
      REVIEW: {
        SECTION_HEADER_PERSONAL: `个人信息`,
        SECTION_HEADER_ADDRESS: `居住地址`,
        CONTINUE_CTA: `继续`,
        DESKTOP: {
          HEADER_TITLE: `身份验证`,
          SUBTITLE: `请输入您的详细信息 `,
        },
        MOBILE: {
          HEADER_TITLE: `谢谢`,
          BODY_0: `请检查扫描后的信息`,
          BODY_1: `如果需要，您可以更新您的信息`,
        },
        FORM: {
          FIRST_NAME_LABEL: `名字`,
          FIRST_NAME_PLACEHOLDER: `名字`,
          MIDDLE_NAME_LABEL: `中间名`,
          MIDDLE_NAME_PLACEHOLDER: `中间名`,
          LAST_NAME_LABEL: `姓氏`,
          LAST_NAME_PLACEHOLDER: `姓氏`,
          DOB_LABEL: `出生日期`,
          DOB_PLACEHOLDER: `YYYY-MM-DD`,
          STREET_ADDRESS_LABEL: `街道地址`,
          STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
          CITY_LABEL: `城市`,
          CITY_PLACEHOLDER: `城市`,
          PROVINCE_LABEL: `省/地区`,
          PROVINCE_PLACEHOLDER: `省/地区`,
          POSTAL_CODE_LABEL: `邮政编码`,
          POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
          HEADER_TITLE: `您的身份证件扫描信息是否正确？`,
          YES: `正确`,
          NO: `不正确`,
          UPDATE_INFO: `请务必更新您的信息，使其为当前信息且准确无误，然后点击“继续”`,
        },
        ERROR_CODE: {
          FIRST_NAME_REQUIRED: `名字是必填项`,
          LAST_NAME_REQUIRED: `姓氏是必填项`,
          DOB_REQUIRED: `出生日期是必填项`,
          ADDRESS_REQUIRED: `住址是必填项`,
          CITY_REQUIRED: `城市是必填项`,
          PROVINCE_REQUIRED: `省/地区是必填项`,
          POSTAL_CODE_REQUIRED: `邮编是必填项`,
          POSTAL_CODE_BAD_FORMAT: `邮政编码显示无效，请检查错误`,
          NUMBERS_ERROR: `名称中含有不可接受数字和特殊字符`,
        },
      },
      END: {
        IDENTITY_SUCCESS: {
          HEADER_TITLE: `成功！`,
          BODY_0: `我们已收到您的身份信息，安信团队成员将进一步审核详细信息并与您联系。`,
          BODY_1: `如有任何问题或疑问，请致电 `,
          BODY_TEL: `${FIInformation.AnS.cs.FI_TEL_PRETTY}`,
          BODY_1_2: `或发送邮件至 `,
          BODY_EMAIL: `${FIInformation.AnS.cs.FI_EMAIL_PRETTY}.`,
          FOOTER: `您现在可以关闭此浏览器窗口`,
        },
        IDENTITY_FAIL: {
          HEADER_TITLE: `嗯…`,
          BODY_0: `很遗憾，我们目前无法自动验证您的身份`,
          BODY_1: `确认电子邮件已发送到您的电子邮件地址。安信代表将很快与您联系`,
          FOOTER: `您现在可以关闭此浏览器窗口`,
        },
        LEAVING: {
          HEADER_TITLE: `离开我们？`,
          BODY_0: `您现在已完成自动验证。您输入的所有信息已从我们的系统中删除`,
          FOOTER: `您现在可以关闭此浏览器窗口`,
        },
      },
      MODAL: {
        CLOSE: "关闭",
        OK: "OK",
      },
      MODAL_COMPATIBLE: {
        HEADER_TITLE: `身份证件兼容性检查`,
        BODY_0: `我们的系统使用机器读取技术和人工智能技术来验证身份证件的真实性。为防止申请延误，请上传与我们系统兼容的政府签发的身份证件`,
        BODY_1: `兼容的政府签发的身份证件包含：`,
        BODY_1_ITEM_0: `您的照片`,
        BODY_1_ITEM_1: `出生日期`,
        BODY_1_ITEM_2: `机器可读字体`,
        BODY_1_ITEM_3: `条形码`,
        BODY_2: `兼容的政府签发的身份证件样本`,
        BODY_2_ITEM_0: `省/地区签发的驾照`,
        BODY_2_ITEM_1: `省/地区签发的带照片的身份证`,
        BODY_2_ITEM_2: `联邦政府签发的印第安人身份安全证书`,
        BODY_2_ITEM_3: `加拿大公民卡`,
        BODY_2_ITEM_4: `护照`,
        BODY_2_ITEM_5: `加拿大军官证`,
        BODY_2_ITEM_6: `永久居民卡`,
        BODY_2_ITEM_7: `新的 (2023 年) 省/地区签发的 PAL 枪支许可证`,
        BODY_2_ITEM_8: `美国/加拿大 Nexus 卡`,
        BODY_3_IMAGE_HEADER: `例如`,
        BODY_3_IMAGE_ALT: `联邦政府签发的印第安人身份证明卡样本`,
        BODY_3: `注意：有些身份证件可能是政府签发、有效且合法的，但无法通过自动验证进行验证。上传这些文件将导致您的申请延迟，并且可能需要进一步验证。`,
        BODY_4: `请勿上传此类内容`,
        BODY_5: `不兼容的政府签发的身份证件：`,
        BODY_5_ITEM_0: `可能是塑料层压的`,
        BODY_5_ITEM_1: `使用机器不可读的字体或脚本，例如“手写”`,
        BODY_6: `不兼容的政府签发的 ID 示例：`,
        BODY_6_ITEM_0: `较旧的层压加拿大公民卡`,
        BODY_6_ITEM_1: `较旧 (2023 年之前) 的 POL 或 PAL 枪支许可证`,
        BODY_6_ITEM_2: `省/地区、地区或国家/部落签发的印第安人身份证`,
        BODY_7: `例如`,
        BODY_7_IMAGE_ALT: `过期且不可接受的加拿大公民证书样本`,
        BODY_7_IMAGE_ALT_1: `不可接受的地区签发的印度身份卡样本`,
      },
      MODAL_SCANTIPS: {
        HEADER_TITLE: "身份证件扫描提示",
        POPUPINFO:
          "请将文档放置在边框内, 直到所有4条边对齐, 边框变为绿色, 如上图所示。图像获取通常是自动进行的",
        POPUPINFO1: "使用对比色鲜明的背景有助于区分文档的边缘和背景",
        POPUPINFO2: "使用光线充足且均匀的区域",
        POPUPINFO3: "确保所有数据可读",
        POPUPINFO4: "避免反光",
      },
      MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "自拍技巧",
        POPUPINFO:
          "如上图所示，请将您的脸部对齐在边框内，数据获取通常是自动进行的",
        POPUPINFO1: "使用光线充足且均匀的区域",
        POPUPINFO2: "避免脸部出现阴影",
        POPUPINFO3: "避免反光",
        POPUPINFO4: `如果您的自拍照预览模糊，请点击“重新拍摄”`,
      },
      MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "成功",
        BODY_0: `您的信息已成功提交`,
        BODY_1: `已向您提供的电子邮件地址发送电子邮件确认，安信的代表将很快与您联系`,
      },
      MODAL_LEAVING_APP: {
        HEADER_TITLE: "这么快就要离开了？",
        BODY_0: `现在离开意味着我们将无法自动验证您的身份`,
        BODY_1: `您确定现在要离开吗？`,
        CANCEL_CTA: `取消`,
        LEAVE_CTA: `马上离开`,
      },
      MONTHS: {
        JAN: `一月`,
        FEB: `二月`,
        MAR: `三月`,
        APR: `四月`,
        MAY: `五月`,
        JUN: `六月`,
        JUL: `七月`,
        AUG: `八月`,
        SEP: `九月`,
        OCT: `十月`,
        NOV: `十一月`,
        DEC: `十二月`,
      },
      GENERIC_ERROR_MESSAGE:
        "我们无法处理您提交的身份证件扫描件。您需要重新尝试扫描您的文件",
    },
    ct: {
      MAIN: {
        HEADER_TITLE: `歡迎使用安信的線上身份驗證系統`,
        SUBHEADER_TITLE: `為驗證您的身份，您需要：`,
        MOBILE_INFO_CARD: `您的手機`,
        ID_INFO_CARD: `政府簽發的有效身分證件`,
        SELFIE_INFO_CARD: `請準備好自拍並拍攝您的身分證件`,
        ENVIRONMENT_INFO_CARD: `請確保您位於光線充足的地方`,
        CONTINUE_CTA: `讓我們開始吧`,
      },
      INFO: {
        HEADER_TITLE: `身份驗證`,
        SUBHEADER_TITLE: `請輸入您的主要信箱和手機號碼。`,
        EMAIL_LABEL: `您的信箱`,
        EMAIL_PLACEHOLDER: `email@email.com`,
        MOBILE_NUMBER_LABEL: `您的手機號碼`,
        MOBILE_NUMBER_PLACEHOLDER: `(000) 000-0000`,
        TERMS_LINK_LABEL_0: `點擊繼續即表示您確認已閱讀並同意`,
        TERMS_LINK_LABEL_1: `條款與條件`,
        TERMS_LINK_LABEL_2: ``,
        CONTINUE_CTA: `繼續`,
        ERROR_CODE: {
          EMAIL_REQUIRED: `信箱是必填項`,
          EMAIL_CHARACTER_LIMIT: `電子郵件必須少於100個字符`,
          EMAIL_VALID: `必須是有效的電子郵件地址`,
          PHONE_VALID: `電話號碼無效`,
          PHONE_REQUIRED: `電話號碼是必填項`,
          TERMS_REQUIRED: `必須接受的條款和條件`,
        },
      },
      VERIFY: {
        HEADER_TITLE: `手機驗證`,
        SUBHEADER_TITLE: `我們已向您提供的手機號碼發送了驗證碼。請輸入驗證碼並繼續。`,
        OTP_LABEL: `驗證碼`,
        OTP_PLACEHOLDER: `驗證碼`,
        RESEND_TITLE: `未收到驗證碼？`,
        RESEND_OTP: `重新發送驗證碼`,
        CONTINUE_CTA: `繼續`,
        ERROR: {
          OTP_REQUIRED: `您必須輸入驗證碼才能繼續。`,
          OTP_INLINE_ERROR: `無效的驗證碼`,
          ERROR_TITLE: `錯誤`,
          ERROR_BODY_0: `您輸入的驗證碼無效`,
          ERROR_BODY_1: `請確保代碼輸入正確；或申請新的代碼`,
        },
      },
      ID_SELECT: {
        HEADER_TITLE: "請選擇ID類型",
        ID_COMPATIBLE: "請確保您的ID 相容",
        ID_SELECT_LABEL: "請選擇政府簽發的ID",
        DUMMY_OPTION: "請選擇ID類型",
        CONTINUE_CTA: `繼續`,
        SPINNER_MSG: `我們需要大約 10 秒左右來驗證您的 ID...請稍候！`,
        OVERLAY_FRONT_ID: `請對準您的 ID 並點擊拍攝`,
        OVERLAY_FRONT_ID_AUTOTAKE: `請將您的 ID 放在邊框中對齊並保持穩定`,
        OVERLAY_BACK_ID: `請對準您的 ID 並點擊拍攝`,
        OVERLAY_BACK_ID_AUTOTAKE: `請將您的 ID 放在邊框中對齊並保持穩定`,
        OVERLAY_SELFIE: `請對準臉部並點擊拍攝`,
        OVERLAY_SELFIE_AUTOTAKE: `請將您的臉部對準方框並保持穩定`,
        VERIFICATION_0: `正在驗證您的身分...請稍候`,
        VERIFICATION_1: `正在驗證ID進行臉部生物辨識...請稍候`,
        VERIFICATION_2: `正在驗證社交生物識別資訊...請稍候`,
        ERROR: {
          ERROR_TITLE: `錯誤`,
          ERROR_BODY: `處理您的訊息時遇到錯誤…`,
          ERROR_SELECT_ID: `請選擇有效的ID類型`,
          ERROR_FRONT_ID: `請拍攝您的身分證件正面`,
          ERROR_BACK_ID: `請拍攝您的身分證件背面`,
          ERROR_SELFIE: `請拍攝一張自拍照`,
          ERROR_LANDSCAPE: `不支援橫向，將裝置旋轉至縱向`,
          ERROR_PORTRAIT: `不支援縱向， 將裝置旋轉至橫向`,
        },
      },
      FRONT: {
        TAKE: "請拍攝您的正面照片",
        TAKE_CAPTURED_TITLE: `拍攝成功！`,
        TAKE_CAPTURED_SUBTITLE: `下面是您正面的照片`,
        RETAKE: "請再拍攝一張您的正面照片",
        TAKE_PASSPORT: "請拍攝一張主頁照片",
        TAKE_PASSPORT_CAPTURED_TITLE: "拍攝成功！",
        TAKE_PASSPORT_CAPTURED_SUBTITLE: "以下是您的主頁照片",
        RETAKE_PASSPORT: "請重新拍攝您的主頁照片",
        TAP_BEGIN: "點擊開始",
        TAP_RETAKE: "需要重新拍攝嗎？點擊即可重新拍攝",
        NEXT: "下一步",
        PROTIP: "專業提示",
        ABOUTYOUINFO:
          "為獲得最佳效果，請在光線充足、背景對比鮮明的地方掃描身分證件",
        ABOUTYOUINFO_CAPTURED: `檢查縮圖。影像品質是否滿意？`,
        ABOUTYOUINFO1: "看更多提示",
        ABOUTYOUINFO1_CAPTURED: `如果不滿意，請點擊「重新拍攝」拍攝新照片`,
      },
      BACK: {
        TAKE: "現在拍一張您的背面照片",
        TAKE_CAPTURED_TITLE: `拍攝成功！`,
        TAKE_CAPTURED_SUBTITLE: `下面是您的背面照片`,
        RETAKE: "請再拍一張您的背面照片",
        TAP_BEGIN: "點擊開始",
        TAP_RETAKE: "需要重新拍攝嗎？點擊即可重新拍攝",
        NEXT: "下一步",
        ABOUTYOUINFO:
          "為獲得最佳效果，請在光線充足、背景對比鮮明的地方掃描您的身分證件",
        ABOUTYOUINFO_CAPTURED: `檢查縮圖。影像品質是否滿意？`,
        ABOUTYOUINFO1: "看更多提示",
        ABOUTYOUINFO1_CAPTURED: `如果不滿意，請點擊「重新拍攝」拍攝新照片`,
      },
      SELFIE: {
        TAKE: "最後來拍一張自拍照吧",
        TAKE_CAPTURED_TITLE: `拍攝成功！`,
        TAKE_CAPTURED_SUBTITLE: `下面是您的照片`,
        RETAKE: "再拍一張自拍照",
        TAP_BEGIN: "點擊開始",
        TAP_RETAKE: "想要重新自拍？點擊重拍",
        ABOUTYOUINFO: "為了獲得最佳效果，請在光線充足的地方自拍",
        ABOUTYOUINFO_CAPTURED: `檢查縮圖。影像品質是否滿意？`,
        ABOUTYOUINFO1: "看更多提示",
        ABOUTYOUINFO1_CAPTURED: `如果不滿意，請點擊「重新拍攝」拍攝新的自拍照`,
        NEXT: "下一步",
      },
      REVIEW: {
        SECTION_HEADER_PERSONAL: `個人資訊`,
        SECTION_HEADER_ADDRESS: `居住地址`,
        CONTINUE_CTA: `繼續`,
        DESKTOP: {
          HEADER_TITLE: `身份驗證`,
          SUBTITLE: `請輸入您的詳細信息`,
        },
        MOBILE: {
          HEADER_TITLE: `謝謝`,
          BODY_0: `請檢查掃描後的信息`,
          BODY_1: `如果需要，您可以更新您的訊息`,
        },
        FORM: {
          FIRST_NAME_LABEL: `名字`,
          FIRST_NAME_PLACEHOLDER: `名字`,
          MIDDLE_NAME_LABEL: `中間名`,
          MIDDLE_NAME_PLACEHOLDER: `中間名`,
          LAST_NAME_LABEL: `姓氏`,
          LAST_NAME_PLACEHOLDER: `姓氏`,
          DOB_LABEL: `出生日期`,
          DOB_PLACEHOLDER: `YYYY-MM-DD`,
          STREET_ADDRESS_LABEL: `街道`,
          STREET_ADDRESS_PLACEHOLDER: `1234 My St.`,
          CITY_LABEL: `城市`,
          CITY_PLACEHOLDER: `城市`,
          PROVINCE_LABEL: `省/地區`,
          PROVINCE_PLACEHOLDER: `省/地區`,
          POSTAL_CODE_LABEL: `郵遞區號`,
          POSTAL_CODE_PLACEHOLDER: `A1A 1A1`,
        },
        NOT_PASSPORT: {
          HEADER_TITLE: `您的身分證件掃描資訊是否正確？`,
          YES: `正確`,
          NO: `不正確`,
          UPDATE_INFO: `請務必更新您的訊息，使其為當前資訊且準確無誤，然後點擊“繼續”`,
        },
        ERROR_CODE: {
          FIRST_NAME_REQUIRED: `名字是必填項`,
          LAST_NAME_REQUIRED: `姓氏是必填項`,
          DOB_REQUIRED: `出生日期為必填項`,
          ADDRESS_REQUIRED: `住址是必填項`,
          CITY_REQUIRED: `城市是必填項`,
          PROVINCE_REQUIRED: `省/地區是必填項`,
          POSTAL_CODE_REQUIRED: `郵編是必填項`,
          POSTAL_CODE_BAD_FORMAT: `郵遞區號顯示無效，請檢查錯誤`,
          NUMBERS_ERROR: `名稱中含有不可接受數字和特殊字符`,
        },
      },
      END: {
        IDENTITY_SUCCESS: {
          HEADER_TITLE: `成功！`,
          BODY_0: `我們已審查您的身份信息，安信團隊成員將進一步審核詳細信息並與您聯繫。`,
          BODY_1: `如有任何問題或疑慮，請致電`,
          BODY_TEL: `${FIInformation.AnS.cs.FI_TEL_PRETTY}`,
          BODY_1_2: ` 或發送郵件至`,
          BODY_EMAIL: `${FIInformation.AnS.ct.FI_EMAIL_PRETTY}`,
          FOOTER: `現在您可以關閉此瀏覽器視窗`,
        },
        IDENTITY_FAIL: {
          HEADER_TITLE: `嗯…`,
          BODY_0: `很遺憾，我們目前無法自動驗證您的身份`,
          BODY_1: `確認電子郵件已傳送到您的電子郵件地址。安信代表將很快與您聯繫`,
          FOOTER: `現在您可以關閉此瀏覽器視窗`,
        },
        LEAVING: {
          HEADER_TITLE: `離開我們？`,
          BODY_0: `您現在已完成自動驗證。您輸入的所有資訊已從我們的系統中刪除`,
          FOOTER: `現在您可以關閉此瀏覽器視窗`,
        },
      },
      MODAL: {
        CLOSE: "關閉",
        OK: "OK",
      },
      MODAL_COMPATIBLE: {
        HEADER_TITLE: `身分證件相容性檢查`,
        BODY_0: `我們的系統使用機器讀取技術和人工智慧技術來驗證身分證件的真實性。為防止申請延誤，請上傳與我們系統相容的政府簽發的身份證件`,
        BODY_1: `由相容的政府簽發的身份證件包含：`,
        BODY_1_ITEM_0: `您的照片`,
        BODY_1_ITEM_1: `出生日期`,
        BODY_1_ITEM_2: `機器可讀字體`,
        BODY_1_ITEM_3: `條碼`,
        BODY_2: `由相容的政府簽發的身份證件樣本`,
        BODY_2_ITEM_0: `省/地區簽發的駕照`,
        BODY_2_ITEM_1: `省/地區簽發的帶照片的身份證`,
        BODY_2_ITEM_2: `聯邦政府簽發的印第安人身分安全證書`,
        BODY_2_ITEM_3: `加拿大公民卡`,
        BODY_2_ITEM_4: `護照`,
        BODY_2_ITEM_5: `加拿大軍官證`,
        BODY_2_ITEM_6: `永久居民卡`,
        BODY_2_ITEM_7: `新的 (2023 年) 省份/地區簽發的 PAL 槍支許可證`,
        BODY_2_ITEM_8: `美國/加拿大 Nexus 卡`,
        BODY_3_IMAGE_HEADER: `例如`,
        BODY_3_IMAGE_ALT: `聯邦政府簽發的印第安人身分證明卡範本`,
        BODY_3: `注意：有些身分證件可能是政府簽發、有效且合法的，但無法透過自動驗證進行驗證。上傳這些文件將導致您的申請延遲，並可能需要進一步驗證。`,
        BODY_4: `請勿上傳此類內容`,
        BODY_5: `不相容的政府簽發的身份證件：`,
        BODY_5_ITEM_0: `可能是塑膠層壓的`,
        BODY_5_ITEM_1: `使用機器不可讀的字體或腳本，例如“手寫”`,
        BODY_6: `不相容的政府簽發的 ID 範例：`,
        BODY_6_ITEM_0: `較舊的層壓加拿大公民卡`,
        BODY_6_ITEM_1: `較舊 (2023 年之前) 的 POL 或 PAL 槍支許可證`,
        BODY_6_ITEM_2: `由省/地區、地區或國家/部落簽發的印第安人身分證`,
        BODY_7: `例如`,
        BODY_7_IMAGE_ALT: `過期且不可接受的加拿大公民證書樣本`,
        BODY_7_IMAGE_ALT_1: `不可接受的地區簽發的印度身份卡樣本`,
      },
      MODAL_SCANTIPS: {
        HEADER_TITLE: "身分證件掃描提示",
        POPUPINFO:
          "請將文件放置在邊框內, 直到所有4條邊對齊, 邊框變為綠色, 如上圖所示。影像擷取通常是自動進行的",
        POPUPINFO1: "使用對比色鮮明的背景有助於區分文件的邊緣和背景",
        POPUPINFO2: "使用光線充足且均勻的區域",
        POPUPINFO3: "確保所有數據可讀",
        POPUPINFO4: "避免反光",
      },
      MODAL_SCANTIPS_FACE: {
        HEADER_TITLE: "自拍技巧",
        POPUPINFO:
          "如上圖所示，請將您的臉部對齊在邊框內，資料擷取通常是自動進行的",
        POPUPINFO1: "使用光線充足且均勻的區域",
        POPUPINFO2: "避免臉部出現陰影",
        POPUPINFO3: "避免反光",
        POPUPINFO4: `如果您的自拍照預覽模糊，請點擊“重新拍攝”`,
      },
      MODAL_SUBMIT_SUCCESS: {
        HEADER_TITLE: "成功",
        BODY_0: `您的資訊已成功提交`,
        BODY_1: `已向您提供的電子郵件地址發送電子郵件確認，安信的代表將很快與您聯繫。`,
      },
      MODAL_LEAVING_APP: {
        HEADER_TITLE: "這麼快就要離開了？",
        BODY_0: `現在離開意味著我們將無法自動驗證您的身份`,
        BODY_1: `您確定現在要離開嗎？`,
        CANCEL_CTA: `取消`,
        LEAVE_CTA: `馬上離開`,
      },
      MONTHS: {
        JAN: `一月`,
        FEB: `二月`,
        MAR: `三月`,
        APR: `四月`,
        MAY: `五月`,
        JUN: `六月`,
        JUL: `七月`,
        AUG: `八月`,
        SEP: `九月`,
        OCT: `十月`,
        NOV: `十一月`,
        DEC: `十二月`,
      },
      GENERIC_ERROR_MESSAGE:
        "我們無法處理您提交的身份證件掃描件。您需要重新嘗試掃描您的文件",
    },
  },
};
